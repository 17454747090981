.cart-section {
    padding: 100px 0;
    display: flex;
    gap: 25px;
    align-items: flex-start;
    .inner-cart {
        width: 100%;
        .cart-area {
            border: 1px solid #e4e4e4;
            border-radius: 10px;
            padding: 20px 30px;
            @include xxs {
                padding: 20px 15px;
            }
            .cart-left-actions {
                border-top: 1px solid #e4e4e4;
                padding-top: 20px;
                margin-top: 0;
                .cart-coupon-form {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    justify-content: center;
                    input {
                        height: 45px;
                        border: 1px solid #e4e4e4;
                        padding: 0 15px;
                        border-radius: 3px;
                        @include xxs {
                            width: 220px;
                        }
                    }
                }
            }
        }
    }
    .cart-checkout-area {
        text-align: center;
        border: 1px solid #e4e4e4;
        width: 100%;
        border-radius: 10px;
        padding: 30px;
        @include xxs {
            padding: 20px 15px;
        }
        &__title {
            font-size: 18px;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 15px;
            margin-bottom: 15px;
            text-align: left;
        }
        .checkout-summary {
            margin-bottom: 30px;
            @include lg {
                font-size: 14px;
            }
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
            .cart-checkout-total {
                border-top: 1px solid #222;
                padding-top: 15px;
            }
        }
        a.rv-1-banner-btn {
            border-radius: 6px;
            height: 45px;
            text-transform: capitalize;
            line-height: 42px;
            background-color: #222;
            color: #fff;
            border: 1px solid #222;
            padding: 0 25px;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            overflow: hidden;
            z-index: 1;
            vertical-align: top;
            &:hover {
                background-color: transparent;
                color: var(--white-f);
                border-color: #003c8b;
            }
            @include xl {
                padding: 0 10px;
            }
            @include lg {
                padding: 0 20px;
            }
            @include xxs {
                padding: 0 10px;
            }
        }
    }
    .rv-1-banner-btn {
        border-radius: 6px;
        height: 45px;
        text-transform: capitalize;
        line-height: 42px;
        background-color: #222;
        color: #fff;
        border: 1px solid #222;
        padding: 0 30px;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        overflow: hidden;
        z-index: 1;
        vertical-align: top;
        transition: 0.3s ease;
        &:hover {
            background-color: transparent;
            color: var(--white-f);
            border-color: #003c8b;
        }
    }
    .wishlist-inner-section {
        width: 100%;
        .cart-area {
            border: 1px solid #e4e4e4;
            border-radius: 10px;
            padding: 20px 30px;
            .cart-left-actions {
                border-top: 1px solid #e4e4e4;
                padding-top: 20px;
                margin-top: 0;
            }
        }
    }
}
table.cart-page-table {
    width: 100%;
    @include lg {
        min-width: max-content;
    }
    tbody, td, tfoot, th, thead {
        border: 0 solid;
        border-color: inherit;
    }
    tr:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
    }
    th {
        padding: 10px;
        border-bottom: 1px solid #e4e4e4;
        text-transform: capitalize;
        text-align: center;
        &:first-child {
            text-align: start;
            padding-left: 0;
        }
    }
    td {
        position: relative;
        text-align: center;
        padding: 25px;
        &:first-child {
            padding-left: 0;
        }
        &:not(:last-of-type) {
            padding-right: 25px;
        }
        .cart-product {
            display: flex;
            align-items: center;
            gap: 20px;
            &__img {
                flex-shrink: 0;
                border: 1px solid #ddd;
                border-radius: 6px;
                img {
                    width: 130px;
                    aspect-ratio: 1;
                    border-radius: 6px;
                    object-fit: contain;
                    padding: 10px;
                    @include md {
                        width: 100px;
                    }
                }
            }
            &__txt h6 {
                text-align: left;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
        .cart-product__quantity {
            width: 140px;
            height: 45px;
            background-color: #f8f8f8;
            line-height: 45px;
            position: relative;
            border-radius: 3px;
            margin-left: auto;
            margin-right: auto;
            .cart-product__quantity-btns {
                position: absolute;
                inset: 0;
                display: flex;
                justify-content: space-between;
                visibility: hidden;
                button {
                    text-align: center;
                    width: 45px;
                    height: 45px;
                    line-height: normal;
                    visibility: visible;
                }
            }
            input[type=number].cart-product-quantity-input {
                width: 100%;
                padding: 0 45px;
                text-align: center;
            }
        }
    }
    tr {
        border: 0 solid;
        border-color: inherit;
        &:not(:last-child) td {
            padding-bottom: 25px;
        }
    }
    @include md {
        min-width: max-content;
    }
}