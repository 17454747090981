/* ----- 46. PROJECT DETAILS PAGE ----- */
.rv-project-details {
    &__cover-slider {
        margin-bottom: 40px;

        @include sm {
            margin-bottom: 25px;
        }
    }

    &__img {
        border-radius: 20px;
        overflow: hidden;

        img {
            height: 520px;
            width: 100%;

            @include lg {
                height: 460px;
            }

            @include md {
                height: 360px;
            }

            @include sm {
                height: 260px;
            }

            @include xxs {
                height: 180px;
            }
        }
    }

    &__txt {
        display: flex;
        gap: 100px;
        margin-bottom: 70px;

        @include lg {
            margin-bottom: 50px;
        }

        @include md {
            margin-bottom: 30px;
        }

        @include md {
            gap: 50px;
        }

        @include sm {
            flex-direction: column-reverse;
            row-gap: 20px;
        }

        .left>*:last-child {
            margin-bottom: 0;
        }
    }

    &__infos {
        flex-shrink: 0;

        @include sm {
            display: flex;
            flex-wrap: wrap;
            column-gap: 8px;
        }
    }

    &-info {
        background-color: rgba(8, 8, 33, 0.06);
        border-radius: 10px;
        padding: 15px 24px 9px;

        @include xxs {
            padding: 10px 15px 4px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &__name {
            color: var(--white-f);
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 1px;
        }

        &__value {
            color: rgb(8, 8, 33, 0.6);
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 173.333%;
        }
    }

    &__features-img {
        border-radius: 20px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__features {
        h5 {
            color: var(--white-f);
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 22px;

            @include md {
                margin-bottom: 15px;
            }

            @include xxs {
                font-size: 22px;
            }
        }

        li {
            position: relative;
            padding-left: 18px;
            color: rgb(0 0 0 / 70%);

            &:not(:last-child) {
                margin-bottom: 18px;

                @include xl {
                    margin-bottom: 12px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 9px;
                background-color: rgb(0 0 0 / 30%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
            }
        }
    }

    &__video {
        margin: 120px 0;

        @include lg {
            margin: 80px 0;
        }

        @include md {
            margin: 60px 0;
        }
    }

    &__bottom-imgs {
        margin-top: 40px;

        @include lg {
            margin-top: 30px;
        }
    }

    &__bottom-img {
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &-bottom-navs {
        background-color: rgba(10, 16, 29, 0.06);
        border-radius: 100px;
        margin-top: 60px;
        position: relative;

        @include lg {
            margin-top: 50px;
        }

        @include md {
            margin-top: 40px;
        }

        @include sm {
            border-radius: 40px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 1px;
            height: calc(100% - 60px);
            background-color: rgba(10, 16, 29, 0.30);

            @include sm {
                height: 1px;
                width: calc(100% - 60px);
            }
        }
    }

    &-bottom-nav {
        border-radius: 10px;
        padding: 18px;
        display: flex;
        align-items: center;
        gap: 20px;

        @include lg {
            padding: 12px;
            gap: 15px;
        }

        @include xxs {
            gap: 10px;
        }

        &:hover {
            color: var(--tl-btn-bg);

            .rv-project-details-bottom-nav-arrow {
                border-color: #003c8b;
                background-color: #003c8b;
                color: var(--white-f);
            }
        }

        &-arrow {
            font-size: 24px;
            width: 84px;
            height: 84px;
            flex-shrink: 0;
            border: 1px solid rgba(5, 5, 5, 0.14);
            border-radius: 50%;
            display: grid;
            place-content: center;
            transition: 0.4s ease;
            color: var(--white-f);

            @include lg {
                width: 74px;
                height: 74px;
            }

            @include md {
                width: 68px;
                height: 68px;
            }

            @include xxs {
                width: 60px;
                height: 60px;
                font-size: 20px;
            }
        }

        &-subtitle {
            margin-bottom: 7px;
            color: rgb(10, 16, 29, 0.7);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;

            @include lg {
                margin-bottom: 3px;
            }

            @include xxs {
                font-size: 14px;
            }
        }

        &-title {
            margin: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: var(--white-f);
            font-size: 24px;
            font-weight: 700;
            transition: 0.4s ease;

            @include lg {
                font-size: 22px;
            }

            @include md {
                font-size: 20px;
            }

            @include xxs {
                font-size: 18px;
            }

            &:hover {
                color: #003c8b;
            }
        }
    }
}

#rv-project-details__cover-slider-nav {
    z-index: 1;

    button {
        background-color: var(--white-f);
        border: none;
        color: var(--white-f);

        @include xs {
            border: 1px solid #ddd;
        }

        &:hover {
            background-color: #003c8b;
            border-color: #003c8b;
            color: var(--white-f);
        }
    }
}