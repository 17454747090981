/* ----- 10. Pricing ----- */
.rv-1-pricing {
    .rv-1-section-heading {
        text-align: center;
    }

    .rv-1-section__title {
        margin-bottom: 48px;

        @include lg {
            margin-bottom: 33px;
        }

        @include sm {
            margin-bottom: 23px;
        }
    }

    &__row {
        --bs-gutter-x: 30px;
        --bs-gutter-y: 30px;

        @include lg {
            --bs-gutter-x: 20px;
            --bs-gutter-y: 20px;
        }

        @include xs {
            --bs-gutter-x: 15px;
            --bs-gutter-y: 15px;
        }
    }

    &-pack {
        background: url(../../img/rv-1-pricing-bg.png) no-repeat center center;
        background-size: 100% 100%;
        padding: 60px 24px;

        @include lg {
            padding: 45px 24px;
        }

        @include sm {
            padding: 35px 15px;
        }

        &__heading {
            padding-left: 15px;
        }

        &__sub-title {
            font-family: var(--mulish);
            font-size: 18px;
            font-weight: 600;
            color: var(--white-f);
            margin-bottom: 11px;

            @include sm {
                margin-bottom: 6px;
            }

            @include xs {
                font-size: 16px;
            }
        }

        &__title {
            font-family: var(--mulish);
            font-weight: 700;
            font-size: 60px;
            color: var(--white-f);
            margin-bottom: 26px;

            @include xl {
                font-size: 45px;
            }

            @include lg {
                margin-bottom: 16px;
                font-size: 40px;
            }

            @include sm {
                font-size: 35px;
                margin-bottom: 10px;
            }

            @include xs {
                font-size: 30px;
            }
        }

        &__details {
            background-color: var(--white-f);
            padding: 40px 40px 45px;
            border: 1px solid rgb(5 5 5 / 08%);

            @include lg {
                padding: 30px 25px;
            }

            @include sm {
                padding: 20px 15px;
            }
        }

        &__info {
            font-family: var(--mulish);
            font-size: 15px;
            font-weight: 500;
            color: rgb(5 5 5 / 70%);
            padding-left: 32px;
            position: relative;

            @include sm {
                padding-left: 27px;
            }

            &:not(:last-child) {
                margin-bottom: 20px;

                @include lg {
                    margin-bottom: 15px;
                }

                @include sm {
                    margin-bottom: 12px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                translate: 0 -50%;
                background: url(../../img/rv-1-icon-3.png) no-repeat center center;
                background-size: 100%;
                width: 20px;
                height: 20px;
                box-shadow: 0 1px 2px rgb(6, 138, 75, 0.3);
                border-radius: 50%;

                @include sm {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &__btn {
            display: flex;
            margin-top: 40px;
            line-height: 48px;

            @include lg {
                margin-top: 25px;
            }

            @include sm {
                margin-top: 15px;
                line-height: 42px;
            }

            &:hover {
                .txt {
                    background-color: var(--orange);
                    border-color: var(--orange);
                    color: var(--white-f);
                }

                .icon {
                    background-color: var(--orange);
                    border-color: var(--orange);

                    img {
                        filter: invert(0);
                    }
                }
            }

            .txt {
                width: 100%;
                text-align: center;
                border-radius: 14px;
                color: var(--white-f);
                background-color: var(--white-f);
                border: 1px solid rgb(5 5 5 / 14%);
            }

            .icon {
                background-color: var(--white-f);
                border: 1px solid rgb(5 5 5 / 14%);
                border-radius: 14px;
                transition: 0.4s ease;

                @include xs {
                    display: none;
                }

                img {
                    transition: 0.4s ease;
                    filter: invert(1);
                }
            }
        }
    }
}

// INDEX-8 PRICING
.rv-8-pricing {
    @include xl {
        padding: 40px 0 80px;
    }

    @include md {
        padding: 30px 0 60px;
    }
}

.rv-8-pricing-pack {
    background: rgba(28, 116, 233, 0.06);
    padding: 50px 40px;

    @include lg {
        padding: 40px 30px;
    }

    @include sm {
        padding: 35px 30px;
    }

    .rv-1-pricing-pack {
        &__title {
            margin-bottom: 0;
        }

        &__heading {
            padding-left: 0;
            border-bottom: 1px solid rgb(5 5 5 / 8%);
            padding-bottom: 25px;
            margin-bottom: 40px;

            @include lg {
                padding-bottom: 15px;
                margin-bottom: 30px;
            }

            @include sm {
                padding-bottom: 10px;
                margin-bottom: 20px;
            }
        }


        &__info::before {
            background-image: url(../../img/rv-8-pricing-icon.png);
            border-radius: 0;
        }
    }

    .rv-3-def-btn {
        width: 100%;
        text-align: center;
        margin-bottom: 54px;
        background-color: transparent;
        border: 1px solid rgb(5 5 5 / 08%);
        color: var(--white-f);

        @include lg {
            margin-bottom: 44px;
        }

        @include sm {
            margin-bottom: 34px;
        }

        &:hover {
            background-color: #1C74E9;
            border-color: #1C74E9;
            color: var(--white-f);
        }
    }
}

// INDEX-11 PRICING
.rv-11-pricing {
    .rv-11-section__heading {
        text-align: center;
    }
}

.rv-11-pricing-plan {
    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 22px;
        font-weight: 700;
        line-height: 127.273%;
        margin-bottom: 30px;

        @include xl {
            margin-bottom: 20px;
        }
    }

    &__txt {
        border-radius: 40px 0px;
        border: 1px solid rgba(5, 5, 5, 0.10);
        background: var(--white-f);
        position: relative;
        margin: 0 30px;
        margin-top: -80px;
        padding: 60px 40px;
        font-family: var(--mulish);

        @include xl {
            margin: 0 20px;
            margin-top: -70px;
            padding: 50px 30px;
        }

        @include lg {
            margin: 0 15px;
            margin-top: -70px;
            padding: 30px 20px 25px;
        }

        >a {
            color: #A98153;
            font-size: 15px;
            font-weight: 700;
            margin-top: 27px;

            @include xl {
                margin-top: 22px;
            }
        }
    }

    &-feature {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            position: relative;
            z-index: 1;
            margin-bottom: 4px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                translate: 0 -50%;
                background: linear-gradient(to right, transparent 50%, rgba(5, 5, 5, 0.24) 50%);
                background-repeat: repeat-x;
                background-size: 6% 100%;
                right: 0;
                height: 1px;
                z-index: -1;
            }
        }

        h6 {
            margin-bottom: 0;
            background-color: var(--white-f);
            color: var(--white-f);
            font-size: 15px;
            font-weight: 700;
        }

        &__title {
            padding-right: 5px;
        }

        &__price {
            padding-left: 5px;
        }
    }

    &__sub-title {
        color: rgb(5 5 5 / 70%);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &.popular {
        .rv-11-pricing-plan__img {
            border-radius: 40px 0;
            overflow: hidden;
        }
    }
}

// INDEX-18 PRICING
.rv-18-price_plan_section {
    padding: 130px 0;

    @media #{$lg,$md} {
        padding: 100px 0;
    }

    @media #{$sm,$mobile,$sm-mobile} {
        padding: 70px 0;
    }

    .rv-18-price_heading {
        color: #142137;
        font-size: 50px;
        font-weight: 800;
        position: relative;
        margin-bottom: 60px;
        z-index: 1;
        text-align: center;
        font-family: $mulish;

        @media #{$sm,$mobile,$sm-mobile} {
            font-size: 40px;
        }

        @media #{$mobile,$sm-mobile} {
            font-size: 35px;
            margin-bottom: 40px;
        }

        @media #{$sm-mobile} {
            font-size: 25px;
            margin-bottom: 40px;
        }

        span {
            position: absolute;
            right: 25%;
            z-index: -1;
            bottom: -35%;
        }
    }

    .rv-18-price-main-area {
        .rv-18-price_nav {
            display: grid;
            margin-bottom: 50px;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            @media #{$md,$sm,$mobile,$sm-mobile} {
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;
            }

            li {
                width: 300px;
                height: 120px;
                background: #FFFFFF;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                border: 1px solid rgba(20, 33, 55, 0.14);
                place-self: center;

                @media #{$xxl,$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    width: 250px;
                }

                @media #{$lg,$sm,$mobile,$sm-mobile} {
                    width: 200px;
                }

                @media #{$sm-mobile} {
                    width: 120px;
                    height: 100px;
                }

                img {
                    @media #{$sm-mobile} {
                        width: 80px;
                        height: auto;
                        object-fit: contain;
                    }
                }

                p {
                    color: #142137;
                    text-align: center;
                    margin: 4px 0 0 0;
                    font-size: 18px;
                    font-weight: 700;

                    @media #{$sm-mobile} {
                        font-size: 16px;
                    }
                }
            }

            .tab.tab--active {
                background: #1C74E9 !important;

                p {
                    color: #FFFFFF !important;
                }

                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    background: #1C74E9;
                    height: 30px;
                    width: 30px;
                    bottom: -13px;
                    transform: rotate(45deg);
                }

                img {
                    filter: brightness(0) invert(1);
                }
            }

        }

        .rv-18-price_wrapper {
            .rv-18-single_pricing_plan {
                .rv-18-pricing_plan_header {
                    background: rgba(28, 116, 233, 0.1);
                    padding: 50px 25px;
                    position: relative;

                    @media #{$lg,$md,$md,$sm,$mobile,$sm-mobile} {
                        padding: 70px 25px;
                    }

                    .rv-18-pricing_plan_best_choice {
                        background: #FFFFFF;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 3px 10px;
                        position: absolute;
                        right: 25px;
                        top: 25px;
                        color: #142137;
                        font-family: $mulish;
                    }

                    .rv-18-pricing_plan_header_title {
                        font-size: 18px;
                        font-weight: 700;
                        color: #142137;
                        margin-bottom: 7px;
                        font-family: $mulish;
                    }

                    .rv-18-pricing_plan_price {
                        font-size: 60px;
                        font-weight: 700;
                        color: #142137;
                        margin: 0;
                        padding-bottom: 30px;
                        font-family: $mulish;

                        @media #{$lg,$md,$md,$sm,$mobile,$sm-mobile} {
                            padding: 0;
                        }

                        sup {
                            font-weight: 500;
                        }
                    }
                }

                .rv-18-pricing_plan_content {
                    padding: 50px 25px;
                    margin: 0 20px;
                    border: 1px solid rgba(20, 33, 55, 0.14);
                    position: relative;
                    top: -40px;
                    background: #FFFFFF;

                    @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                        padding: 40px 20px;
                    }

                    .rv-18-pricing_plan_time {
                        position: absolute;
                        top: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #1C74E9;
                        color: #FFFFFF;
                        width: 114px;
                        height: 28px;
                        text-align: center;
                        padding: 2px 0;
                    }

                    li {
                        margin-bottom: 15px;
                        font-family: $mulish;

                        @media #{$sm-mobile} {
                            font-size: 14px;
                        }

                        i {
                            margin-right: 20px;
                            height: 25px;
                            width: 25px;
                            text-align: center;
                            line-height: 25px;
                            border-radius: 50%;
                            font-size: 12px;

                            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                                margin-right: 10px;
                            }
                        }

                        i.fa-check {
                            background: rgba(34, 255, 127);
                            color: #FFFFFF;
                        }

                        i.fa-times {
                            background: rgba(20, 33, 55, 0.2);
                            color: #525E73;
                        }
                    }

                    .rv-18-pricing_plan_footer {
                        a {
                            color: #FFFFFF;
                            font-weight: 700;
                            height: 50px;
                            background: #142137;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #142137;
                            margin-top: 35px;
                            transition: all 0.4s ease;
                            font-family: $mulish;

                            &:hover {
                                background: #1C74E9;
                                color: #FFFFFF;
                                letter-spacing: 1.1px;

                                i {
                                    transform: rotate(-45deg);
                                }
                            }

                            i {
                                margin-left: 7px;
                                transition: all 0.4s ease;
                            }
                        }

                    }
                }
            }
        }
    }
}

// INDEX-19 PRICING 
.rv-19-price_main_section {
    padding: 130px 0;

    @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
        padding: 100px 0;
    }

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding: 70px 0;
    }

    @media #{$sm,$mobile,$sm-mobile} {
        padding: 50px 0;
    }

    .rv-19-price_heading {
        color: #142137;
        font-size: 70px;
        font-weight: 800;
        margin-bottom: 60px;
        text-align: center;
        font-family: $mulish;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            font-size: 55px;
        }

        @media #{$mobile,$sm-mobile} {
            font-size: 45px;
        }
    }

    .rv-19-single_pricing_plan {

        border: 1px solid rgba(20, 33, 55, 0.2);

        @media #{$md,$sm,$mobile,$sm-mobile} {
            margin-bottom: 30px;
        }

        .rv-19-pricing_plan_header_title {
            font-size: 18px;
            font-weight: 700;
            color: #142137;
            background: #DFE11B;
            width: 100%;
            padding: 25px 50px;
            font-family: $mulish;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                padding: 15px 20px;
            }
        }

        .rv-19-pricing_plan_price {
            font-size: 60px;
            font-weight: 700;
            color: #142137;
            margin: 0;
            padding: 10px 50px;
            border-bottom: 1px solid rgba(20, 33, 55, 0.14);
            margin-bottom: 50px;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                padding: 10px 20px;
                font-size: 40px;
                margin-bottom: 30px;
            }

            sub {
                font-weight: 500;
                font-size: 14px;
                color: #142137;
            }
        }

        ul {
            padding: 0 50px;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                padding: 0 20px;
            }

            li {
                color: #A6A6A6;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 15px;

                span {
                    margin-right: 25px;
                }
            }

            .rv-19-op {
                color: #2B3E60;

                span {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: linear-gradient(143deg, rgba(240, 242, 0, 0.87) 0%, #B6B805 90.81%), rgba(43, 88, 163, 0.20);
                    margin-right: 15px;
                }
            }

        }

        .rv-19-price_plan_button_area {
            width: 100%;
            padding: 0 50px;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                padding: 0 20px;
            }

            .rv-19-price_plan_btn {
                width: 100%;
                height: 54px;
                font-size: 16px;
                font-weight: 700;
                color: #142137;
                border: 1px solid rgba(20, 33, 55, 0.14);
                text-align: center;
                font-family: "Mulish", sans-serif;
                line-height: 50px;
                margin: 60px 0;

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    margin: 30px 0;
                }

                i {
                    transition: all 0.4s ease;
                    border-left: 1px solid rgba(20, 33, 55, 0.14);
                    height: 100%;
                    width: 50px;
                    text-align: center;
                    line-height: 54px;
                    margin-right: 0;
                    float: right;

                    @media #{$mobile,$sm-mobile} {
                        widows: 45px;
                    }
                }

                &:hover {
                    background: #DFE11B;
                    border: 1px solid transparent;

                    i {
                        border-left: 1px solid #FFFFFF;

                    }
                }
            }
        }
    }
}

// INDEX-20 PRICING
.rv-20-price_section {
    padding-bottom: 90px;
    margin-top: -60px;

    @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
        padding-bottom: 100px;
    }

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-bottom: 70px;
    }

    @media #{$sm,$mobile,$sm-mobile} {
        padding-bottom: 50px;
    }

    .rv-20-price_section_heading {
        text-align: center;

        .rv-20-price_sub_title {
            color: #081C15;
            font-weight: 500;
            font-size: 16px;
            width: 140px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border: 1px solid var(--white-f);
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 4px;
            margin: 0 auto;
            font-family: $mulish;

            span {
                height: 5px;
                width: 5px;
                background: #081C15;
                border-radius: 50%;
            }
        }

        .rv-20-price_section_title {
            font-size: 54px;
            font-weight: 800;
            color: #081C15;
            margin-bottom: 50px;
            margin-top: 20px;
            font-family: $mulish;

            @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                font-size: 40px;
            }

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                font-size: 28px;
                margin-bottom: 35px;
            }

            @media #{$md,$mobile,$sm-mobile} {
                font-size: 22px;
                margin-bottom: 30px;
            }
        }
    }

    .rv-20-single_pricing_plan {
        border: 1px solid var(--white-f);
        padding: 40px 0;
        transition: all .4s ease;
        margin-bottom: 30px;

        &:hover {
            background: #003d8b;
        }

        &:hover .rv-20-pricing_plan_header .rv-20-pricing_plan_header_title {
            color: #FFFFFF;
        }

        &:hover .rv-20-pricing_plan_header .rv-20-pricing_plan_header_desc {
            color: rgba(255, 255, 255, 0.6);
        }

        &:hover .rv-20-pricing_plan_price {
            background: #FFFFFF;
            color: #081C15;
        }

        &:hover .rv-20-single_pricing_bg img {
            opacity: 10%;
            transition: 0.4s ease;
        }

        .rv-20-pricing_plan_header {
            padding: 0px 30px;
            position: relative;
            margin-bottom: 20px;

            @media #{$sm-mobile} {
                padding: 0px 20px;
            }

            .rv-20-pricing_plan_header_title {
                font-size: 18px;
                font-weight: 700;
                color: #081C15;
                margin-bottom: 7px;
                transition: all .4s ease;
                font-family: $mulish;
            }

            .rv-20-pricing_plan_header_desc {
                color: var(--white-f);
                font-size: 15px;
                font-weight: 400;
                margin: 0;
                transition: all .4s ease;
                font-family: $mulish;
            }
        }

        .rv-20-pricing_plan_price {
            color: #FFFFFF;
            font-size: 40px;
            font-weight: 700;
            padding: 10px 40px;
            background: #003d8b;
            margin: 0 0 0 30px;
            transition: all .4s ease;
            position: relative;
            z-index: 2;
            font-family: $mulish;

            @media #{$sm-mobile} {
                margin: 0 0 0 20px;
                padding: 10px 30px;
            }

            sub {
                font-size: 15px;
                font-weight: 400;
                font-family: $mulish;
            }
        }

        &:hover ul li {
            color: #C0D2CA;

            i {
                color: #FFFFFF;
            }
        }

        ul {
            padding: 35px 30px;
            position: relative;
            z-index: 1;

            @media #{$sm-mobile} {
                padding: 30px 20px;
            }

            li {
                margin-bottom: 15px;
                color: #6B7773;
                font-size: 15px;
                font-weight: 500;
                transition: all 0.4s ease;

                i {
                    margin-right: 20px;
                    font-size: 18px;
                    color: #003d8b;
                    transition: all 0.4s ease;
                }
            }

            .rv-20-single_pricing_bg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                height: 135%;
                width: 320px;

                img {
                    height: 100%;
                    width: 100%;
                    transition: all 0.4s ease;
                }

            }
        }

        .rv-20-pricing_plan_btn {
            font-family: $mulish;
            color: #081C15;
            font-weight: 700;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.4s ease;
            margin: 0 30px;
            border: 1px solid var(--white-f);
            background: #FFFFFF;
            position: relative;
            z-index: 2;

            @media #{$sm-mobile} {
                margin: 0px 20px;
            }

            &:hover {
                background: #FFFFFF;
                letter-spacing: 1.1px;
            }
        }

    }
}