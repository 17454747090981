/* ----- 05. Banner ----- */
// INDEX-1 BANNER
.rv-1-banner {
    background-color: var(--green);
    position: relative;
    z-index: 1;

    &__txt {
        padding: 50px;
        padding-left: 180px;

        @include xxl {
            padding: 50px;
        }

        @include lg {
            padding: 40px;
        }

        @include md {
            padding: 30px;
        }

        @include sm {
            padding: 45px 30px;
            text-align: center;
        }

        @include xxs {
            padding: 35px 30px;
        }
    }

    &__title {
        font-size: 100px;
        line-height: 104%;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--white-f);
        margin-bottom: 40px;

        @include xxl {
            font-size: 90px;
        }

        @include xl {
            font-size: 75px;
            margin-bottom: 30px;
        }

        @include lg {
            font-size: 65px;
        }

        @include md {
            font-size: 42px;
            margin-bottom: 20px;
        }

        @include sm {
            font-size: 35px;
        }

        @include xxs {

            font-size: 30px;
        }
    }

    &__infos {
        display: flex;
        column-gap: 40px;
        row-gap: 15px;
        margin-bottom: 64px;

        @include xl {
            margin-bottom: 34px;
        }

        @include lg {
            gap: 25px;
            row-gap: 15px;
            flex-wrap: wrap;
        }

        @include md {
            margin-bottom: 24px;
        }

        @include sm {
            justify-content: center;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        font-family: var(--mulish);
        color: var(--white-f);

        i {
            font-size: 20px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 20px;

        @include md {
            row-gap: 10px;
            flex-wrap: wrap;
        }

        @include sm {
            justify-content: center;
        }
    }

    &__socials {
        display: flex;
        gap: 10px;
        padding-left: 180px;
        position: relative;

        @include xl {
            padding-left: 80px;
        }

        @include lg {
            padding-left: 50px;
        }

        @include md {
            padding-left: 0;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            translate: 0 -50%;
            width: 160px;
            height: 2px;
            background-color: rgb(255 255 255 / 24%);

            @include xl {
                width: 70px;
            }

            @include lg {
                width: 40px;
            }

            @include md {
                content: none;
            }
        }

        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 50%;
            color: var(--white-f);
            border: 1.5px solid rgb(255 255 255 / 14%);
            font-size: 14px;

            &:hover {
                background-color: var(--orange);
                border-color: var(--orange);
            }
        }
    }

    &__vector {
        position: absolute;
        z-index: -1;
        animation: spin 2.5s linear infinite;

        &-1 {
            top: 63px;
            left: 57px;
        }

        &-2 {
            top: 163px;
            left: 788px;
        }

        &-3 {
            bottom: 161px;
            left: 118px;
            animation: moveX 1.5s linear alternate infinite;
        }

        &-4 {
            top: 458px;
            left: 802px;
        }

        &-5 {
            bottom: 88px;
            left: 880px;
            animation: moveX 1.5s linear alternate infinite;
        }
    }
}

@keyframes spin {
    0% {
        rotate: 0;
    }

    100% {
        rotate: 360deg;
    }
}

@keyframes moveX {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(20px);
    }
}

.reveal {
    position: relative;
    width: 100%;
    overflow: hidden;

    img {
        height: 100%;
        object-fit: cover;
        transform-origin: left;
        transition: none;
    }
}

// INDEX-2 BANNER
.rv-2-banner {
    background-color: #292929;
    overflow: hidden;

    &__img {
        padding-top: 45px;
        background: url(../../img/rv-2-banner-img-bg.png) no-repeat center top;
        background-size: contain;
        animation: bgPosition 1.5s ease forwards;
        display: flex;
        justify-content: flex-end;

        @include xxl {
            justify-content: center;
        }

        @include sm {
            padding-top: 0;
        }

        img {
            max-width: none;

            @include xxl {
                max-width: 530px;
            }

            @include xl {
                max-width: 490px;
            }

            @include lg {
                max-width: 460px;
            }

            @include sm {
                max-width: 300px;
            }

            @include xxs {
                max-width: 280px;
            }
        }
    }

    &__txt {
        padding: 220px 0 235px;

        @include xxl {
            padding: 160px 0 195px;
        }

        @include xl {
            padding: 140px 0 145px;
        }

        @include md {
            padding: 140px 0 115px;
        }

        @include sm {
            padding: 110px 0 55px;
        }

        @include xxs {
            padding: 80px 0 25px;
        }
    }

    &__title {
        font-size: 110px;
        font-weight: 800;
        text-transform: uppercase;
        color: var(--white-f);
        line-height: 94.545%;
        margin-bottom: 36px;

        @include xxl {
            font-size: 90px;
        }

        @include xl {
            font-size: 80px;
            margin-bottom: 26px;
        }

        @include lg {
            font-size: 75px;
        }

        @include md {
            font-size: 65px;
        }

        @include sm {
            font-size: 50px;
            margin-bottom: 16px;
        }

        @include xs {
            font-size: 45px;
        }

        @include xxs {
            font-size: 40px;
        }

        .stroked-txt {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px rgb(255 255 255 / 30%);
            font-weight: 900;
        }
    }

    &__label {
        position: relative;
        color: var(--white-f);
        padding-left: 160px;
        font-weight: 500;
        font-size: 20px;
        color: rgb(255 255 255 / 60%);
        font-family: var(--mulish);
        margin-bottom: 62px;

        @include xxl {
            margin-bottom: 42px;
        }

        @include xl {
            margin-bottom: 27px;
        }

        @include md {
            padding-left: 80px;
            font-size: 18px;
        }

        @include sm {
            margin-bottom: 17px;
        }

        @include xxs {
            margin-bottom: 12px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            background: linear-gradient(270deg, #DAED1A -0.71%, rgba(218, 237, 26, 0.00) 100%);
            width: 140px;
            height: 2px;
            top: 50%;
            translate: 0 -50%;
            transition: 0.4s ease;

            @include md {
                width: 60px;
            }
        }
    }

    &__infos {
        display: flex;
        gap: 40px;

        @include md {
            flex-wrap: wrap;
            gap: 20px 30px;
        }

        @include xxs {
            gap: 20px;
            justify-content: center;
        }
    }

    &-info {
        display: flex;
        align-items: center;
        gap: 20px;
        color: var(--white-f);

        @include xxl {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        @include sm {
            align-items: center;
            text-align: center;
        }

        &__icon {
            flex-shrink: 0;

            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;

                @include xxl {
                    width: 60px;
                    height: 60px;
                }

                @include xl {
                    width: 50px;
                    height: 50px;
                }

                @include lg {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        &__title {
            font-family: var(--mulish);
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;

            @include lg {
                font-size: 18px;
            }

            @include xs {
                font-size: 16px;
            }
        }

        &__sub-title {
            font-family: var(--mulish);
            font-size: 16px;
            font-weight: 500;
            color: rgb(255 255 255 / 70%);
        }
    }
}

@keyframes bgPosition {
    0% {
        background-position: center center;
    }

    100% {
        background-position: center top;
    }
}

// INDEX-3 BANNER 
.rv-3-banner {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__slide {
        padding: 280px 0 363px;
        background: url(../../img/rv-3-banner-bg-1.jpg) no-repeat center center;
        background-size: cover;
        z-index: 1;

        @include xxl {
            padding: 230px 0 263px;
        }

        @include xl {
            padding: 230px 0 233px;
        }

        @include lg {
            padding: 170px 0 203px;
        }

        @include md {
            padding: 160px 0 163px;
        }

        @include sm {
            padding: 150px 0 143px;
        }

        @include xs {
            padding: 140px 0 123px;
        }

        &::before {
            content: attr(data-text);
            position: absolute;
            bottom: 80px;
            left: 50%;
            translate: -50% 0;
            z-index: -1;
            color: rgba(255, 255, 255, 0.1);
            text-align: center;
            font-size: 300px;
            font-weight: 600;
            line-height: 74%;
            text-transform: uppercase;
            width: 100%;
            font-size: clamp(50px, 15.6vw, 300px);

            @include sm {
                bottom: 60px;
            }
        }

        &--2 {
            background-image: url(../../img/rv-3-banner-bg-2.jpg);
        }

        &--3 {
            background-image: url(../../img/rv-3-banner-bg-3.jpg);
        }
    }

    .swiper-slide-active {
        .rv-3-banner__sub-title {
            &::before {
                height: calc(100% + 20px);
            }

            &::after {
                width: calc(100% + 30px);
            }
        }

        .rv-3-banner__btns {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &-left,
    &-right {
        position: absolute;
        left: 0;
        width: 80px;
        height: 100%;
        z-index: 1;
        top: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.1);

        @include md {
            display: none;
        }
    }

    &-right {
        right: 0;
        left: auto;
        background-color: var(--white-f);
    }

    &__socials.rv-1-socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 2;
        gap: 42px;
        color: #fff;
        top: 50%;
        left: 20px;
        translate: 0 -50%;

        @include lg {
            gap: 32px;
        }

        h6 {
            position: relative;
            text-transform: capitalize;
            writing-mode: vertical-lr;
            margin: 0;
            font-family: var(--mulish);
            font-size: 16px;
            font-weight: 700;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                translate: -50% 0;
                width: 2px;
                height: 14px;
                top: calc(100% + 8px);
                background-color: var(--white-f);
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;

            @include lg {
                gap: 10px;
            }
        }

        a {
            border: 1px solid rgb(255 255 255 / 20%);
            color: var(--white-f);

            @include lg {
                font-size: 14px;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }

            &:hover {
                background-color: #A97240;
                border-color: #A97240;
            }
        }
    }

    &__txt {
        text-align: center;
        color: var(--white-f);
    }

    &__sub-title {
        margin: auto;
        margin-bottom: 32px;

        @include md {
            margin-bottom: 22px;
        }

        &::before {
            height: 0;
            background-color: rgba(255, 255, 255, 0.30);
        }

        &::after {
            width: 0;
            background-color: rgba(255, 255, 255, 0.30);
        }
    }

    &__title {
        font-size: 70px;
        font-weight: 600;
        line-height: 114.286%;
        margin-bottom: 50px;

        @include xl {
            font-size: 55px;
        }

        @include lg {
            font-size: 45px;
            margin-bottom: 35px;
        }

        @include md {
            font-size: 40px;
            margin-bottom: 25px;
        }

        @include sm {
            margin-bottom: 25px;
            font-size: 34px;
        }

        @include xs {
            margin-bottom: 20px;
        }

        @include xxs {
            font-size: 28px;
        }
    }

    &__btns {
        display: flex;
        gap: 15px;
        justify-content: center;
        transform: translateY(100%);
        transition: 0.8s ease 0.2s;
        opacity: 0;

        a {
            &:hover {
                background-color: transparent;
            }

            &:nth-child(2) {
                border: 1px solid rgba(255, 255, 255, 0.20);
                background-color: transparent;

                &:hover {
                    background-color: var(--white-f);
                    border-color: var(--white-f);
                    color: var(--white-f);
                }
            }
        }
    }

    &-swiper-pagination.swiper-pagination-bullets {
        position: absolute;
        bottom: 0;
        z-index: 2;
        color: white;
        display: flex;
        justify-content: center;
        gap: 70px;
        padding: 25px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        @include md {
            gap: 50px;
        }

        @include sm {
            padding: 15px 0;
        }

        .swiper-pagination-bullet {
            width: auto;
            height: auto;
            background: none;
            border-bottom: 1px solid transparent;
            color: rgb(255, 255, 255, 0.3);
            font-size: 20px;
            font-weight: 500;
            border-radius: 0;
            opacity: 1;
            margin: 0;
            transition: 0.4s ease;
        }

        .swiper-pagination-bullet-active {
            border-color: var(--white-f);
            color: var(--white-f);
        }
    }
}

// INDEX-4 BANNER 
.rv-4-banner {
    padding: 30px 0;

    &-slide {
        overflow: hidden;

        &.swiper-slide-active {
            .rv-4-banner-slide__txt {
                transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
                opacity: 1;
            }
        }

        &__img img {
            width: 100%;

            @include xxs {
                height: 350px;
            }
        }

        &__txt {
            position: absolute;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: rgba(8, 28, 21, 0.50);
            backdrop-filter: blur(5px);
            inset: 0;
            text-align: center;
            padding: 50px 60px;
            color: var(--white-f);
            inset: 23% 15%;
            height: max-content;
            top: auto;
            overflow: hidden;

            // for animation
            transition: 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            transform: rotateY(-35deg) rotateX(-20deg) translate(250px, 250px) skew(12deg, 15deg);
            opacity: 0;

            @include xxl {
                padding: 45px 40px;
                inset: 23% 12%;
            }

            @include xl {
                padding: 30px 35px;
                inset: 23% 10%;
            }

            @include xxs {
                inset: 0;
                top: auto;
                padding: 20px 25px;
            }
        }

        &__infos {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-bottom: 11px;

            @include xxs {
                gap: 10px;
                flex-wrap: wrap;
                row-gap: 0;
            }

            li {
                font-size: 14px;
                font-weight: 400;
                line-height: 200%;


                &:not(:last-child) {
                    padding-right: 16px;
                    position: relative;

                    @include xxs {
                        padding-right: 14px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        translate: 0 -50%;
                        right: 0;
                        background-color: var(--white-f);
                        border-radius: 50%;
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }

        &__title {
            font-size: 40px;
            font-weight: 600;
            line-height: 125%;
            margin-bottom: 24px;
            font-size: clamp(20px, 2.18vw, 40px);

            @include xl {
                margin-bottom: 14px;
            }

            a {
                background: linear-gradient(to right, var(--white-f), var(--white-f)) no-repeat bottom left;
                background-size: 0% 2px;
                display: inline;

                &:hover {
                    background-size: 100% 2px;
                }
            }
        }

        &-author {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            &__txt {
                margin-bottom: 0;

                span {
                    color: rgb(255 255 255 / 70%);
                }

                a:hover {
                    color: #a90e20;
                }
            }
        }
    }
}

// INDEX-5 BANNER
.rv-5-banner {
    position: relative;

    .container {
        @media screen and (min-width: 1400px) {
            max-width: 1920px;
            padding: 0;
        }
    }

    &__slider img {
        aspect-ratio: 1903 / 870;

        @include xs {
            aspect-ratio: 1903/1000;
        }

        @include xxs {
            aspect-ratio: 1813/1120;
        }
    }

    &__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 895px;
        z-index: 1;
        display: flex;

        @include xxl {
            max-width: 825px;
        }

        @include xl {
            max-width: 725px;
        }

        @include lg {
            max-width: 605px;
        }

        @include md {
            position: relative;
            margin: auto;
            margin-top: -80px;
        }

        @include xs {
            max-width: 100%;
        }

        @include xxs {
            flex-wrap: wrap;

            >* {
                width: 100%;
            }
        }
    }

    &__txt-slider {
        width: calc(100% - 100px);

        @include xs {
            width: calc(100% - 95px);
        }

        @include xxs {
            width: 100%;
        }

        &-nav {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            background: rgba(25, 36, 29, 0.90);
            backdrop-filter: blur(2px);
            padding: 70px 40px;
            min-width: 140px;
            justify-content: space-between;

            @include xl {
                padding: 70px 20px;
                min-width: 120px;
            }

            @include lg {
                min-width: 100px;
            }

            @include md {
                padding: 40px 20px;
            }

            @include sm {
                padding: 30px 20px;
            }

            @include xs {
                min-width: 95px;
            }

            @include xxs {
                flex-direction: row-reverse;
                padding: 10px 20px;
            }

            button {
                color: var(--white-f);

                &.swiper-button-disabled {
                    opacity: 50%;
                }
            }
        }
    }

    &__txt-slide {
        padding: 140px 120px 135px;
        background: url(../../img/rv-5-banner-txt-bg.png) #404B44 no-repeat center center;
        background-size: auto;
        align-self: flex-end;

        @include xxl {
            padding: 100px 70px 95px;
        }

        @include xl {
            padding: 70px 60px 66px;
        }

        @include lg {
            padding: 55px 40px 51px;
        }

        @include md {
            padding: 40px 40px 36px;
        }

        @include sm {
            padding: 30px 30px 26px;
        }

        @include xxs {
            padding: 25px 20px 21px;
        }
    }

    &__title {
        color: var(--white-f);
        font-family: var(--philosopher);
        font-size: 74px;
        font-weight: 700;
        line-height: 89.189%;
        margin-bottom: 30px;

        @include xl {
            font-size: 64px;
        }

        @include lg {
            font-size: 54px;
            margin-bottom: 20px;
        }

        @include md {
            font-size: 45px;
            margin-bottom: 15px;
        }

        @include sm {
            font-size: 42px;
            margin-bottom: 15px;
        }

        @include xs {
            font-size: 38px;
        }

        @include xxs {
            font-size: 35px;
        }
    }

    &__sub-title {
        color: rgb(255 255 255 / 70%);
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;

        @include lg {
            font-size: 22px;
        }

        @include md {
            font-size: 20px;
        }

        @include xs {
            font-size: 18px;
        }

        @include xxs {
            font-size: 16px;
            line-height: 1.4;
        }
    }
}

// INDEX-6 BANNER 
.rv-6-banner {
    position: relative;
    padding: 208px 0;
    background-color: #f5f5f5;

    @include xxl {
        padding: 168px 70px;
    }

    @include xl {
        padding: 118px 70px;
    }

    @include lg {
        padding: 108px 70px;
    }

    @include md {
        padding: 68px 70px;
    }

    @include sm {
        padding: 60px;
    }

    @include xxs {
        padding: 60px 0;
    }

    &__slide {
        opacity: 0 !important;

        &.swiper-slide-active {
            opacity: 1 !important;

            .rv-6-def-btn {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &__txt {
        @include md {
            text-align: center;
        }
    }

    &__title {
        color: var(--white-f);
        font-size: 70px;
        font-weight: 700;
        line-height: 114.286%;
        margin-bottom: 28px;

        @include xxl {
            font-size: 65px;
        }

        @include xl {
            font-size: 50px;
        }

        @include lg {
            font-size: 40px;
            margin-bottom: 18px;
        }

        @include md {
            font-size: 35px;
            margin-bottom: 13px;
        }

        @include xs {
            font-size: 32px;
        }

        @include xxs {
            font-size: 28px;
            margin-bottom: 8px;
        }
    }

    &__descr {
        color: rgb(5, 5, 5, 0.6);
        font-family: var(--mulish);
        font-size: 18px;
        font-weight: 500;
        line-height: 155.556%;
        margin-bottom: 37px;

        @include lg {
            margin-bottom: 27px;
        }

        @include md {
            margin-bottom: 22px;
        }

        @include xs {
            font-size: 16px;
        }

        @include xxs {
            margin-bottom: 17px;
        }
    }

    .rv-6-def-btn {
        transform: translateY(100%);
        opacity: 0;
        transition: 0.4s ease, opacity 1s ease 1s, transform 1s ease 1s;
    }

    &__img {
        text-align: end;

        @include md {
            text-align: center;
        }

        img {
            mask: url(../../img/rv-6-banner-shape.svg) center center no-repeat;
            mask-size: contain;
        }
    }

    .rv-3-banner__socials.rv-1-socials {
        color: var(--white-f);
        flex-direction: column-reverse;
        left: 40px;

        @include xxl {
            left: 20px;
        }

        @include sm {
            left: 12px;
        }

        @include xxs {
            flex-direction: row;
            position: static;
            translate: 0 0;
            justify-content: center;
            margin: 15px 0;
        }

        h6 {
            scale: -1;

            @include xxs {
                scale: 1;
                writing-mode: horizontal-tb;
            }

            &::before {
                background-color: var(--white-f);

                @include xxs {
                    left: calc(100% + 8px);
                    width: 14px;
                    height: 2px;
                    top: 50%;
                    translate: 0 -50%;
                }
            }
        }

        ul {
            gap: 8px;

            @include xxs {
                flex-direction: row;
            }
        }

        a {
            color: var(--white-f);
            background-color: rgba(5, 5, 5, 0.10);

            &:hover {
                border-color: #F2584C;
                background-color: #F2584C;
                color: var(--white-f);
            }
        }
    }
}

#rv-6-banner__slider-nav {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    right: 40px;
    display: flex;
    flex-direction: column;

    @include xxl {
        right: 20px;
    }

    @include xl {
        right: 15px;
    }

    @include sm {
        right: 12px;
    }

    @include xxs {
        position: static;
        translate: 0 0;
        flex-direction: row;
        justify-content: center;
    }

    button {
        font-size: 16px;
    }
}

// INDEX-7 BANNER
.rv-7-banner {
    background: url(../../img/rv-7-banner-bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 85px 55px 135px;
    position: relative;
    overflow: hidden;

    @include xl {
        padding: 65px 55px 115px;
    }

    @include md {
        text-align: center;
    }

    @include xs {
        padding: 65px 0 115px;
    }

    &__slide {
        overflow: hidden;

        &.swiper-slide-active {
            .rv-7-banner__infos {
                >* {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            .rv-7-def-btn {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &__sub-title {
        color: #E9A727;
        font-size: 20px;
        margin-bottom: 19px;

        @include sm {
            margin-bottom: 9px;
        }

        @include xxs {
            font-size: 18px;
        }
    }

    &__title {
        color: var(--white-f);
        font-size: 64px;
        font-weight: 700;
        line-height: 109.375%;
        margin-bottom: 38px;

        @include xl {
            font-size: 60px;
        }

        @include lg {
            font-size: 50px;
            margin-bottom: 28px;
        }

        @include md {
            font-size: 45px;
            margin-bottom: 18px;
        }

        @include sm {
            font-size: 40px;
        }

        @include xs {
            font-size: 35px;
        }

        @include xxs {
            font-size: 30px;
        }
    }

    &__infos {
        align-items: center;

        @include xl {
            gap: 25px;
        }

        @include lg {
            flex-wrap: wrap;
            row-gap: 20px;
        }

        @include md {
            justify-content: center;
        }

        @include sm {
            row-gap: 10px;
        }

        >* {
            transform: translateX(-20px);
            opacity: 0;
            transition: 0.6s ease;

            &:nth-child(2) {
                transition-delay: 0.2s;
            }

            &:nth-child(3) {
                transition-delay: 0.4s;
            }
        }
    }

    &-info {
        img {
            max-width: 40px;
            max-height: 40px;
            object-fit: contain;

            @include sm {
                max-width: 30px;
                max-height: 30px;
            }

            @include xxs {
                max-width: 25px;
                max-height: 25px;
            }
        }
    }

    .rv-7-def-btn {
        margin-top: 54px;
        transform: translateY(80%);
        opacity: 0;
        transition: 0.4s ease, transform 0.8s ease 0.3s, opacity 1.5s ease 0.3s;

        @include xl {
            margin-top: 44px;
        }

        @include lg {
            margin-top: 34px;
        }

        @include md {
            margin-top: 24px;
        }
    }

    &__img {
        text-align: end;

        @include md {
            text-align: center;
        }

        img {
            max-height: 450px;
            object-fit: contain;

            @include sm {
                max-width: 300px;
            }
        }
    }

    &__vector {
        position: absolute;
        pointer-events: none;

        &--1 {
            left: 0;
            top: 65px;
        }

        &--2 {
            bottom: 0;
            right: 0;
        }
    }
}

.rv-7-slider-nav {
    position: absolute;
    top: 50%;
    translate: -50% -50%;
    left: 50%;
    width: calc(100% - 100px);
    justify-content: space-between;
    visibility: hidden;

    @include xxl {
        width: calc(100% - 15px);
    }

    @include xs {
        position: static;
        translate: none;
        justify-content: center;
        gap: 10px;
        padding-top: 15px;
    }

    button {
        visibility: visible;
        background-color: rgb(255 255 255 / 20%);
        color: var(--white-f);
        border-color: rgb(255 255 255 / 5%);

        &:hover {
            background-color: var(--white-f);
            color: var(--white-f);
            border-color: var(--white-f);
        }
    }
}

.rv-7-def-btn {
    border-radius: 30px;
    background: var(--white-f);
    height: 45px;
    line-height: 45px;
}

// INDEX-8 BANNER
.rv-8-banner {
    background-color: #033E84;
    position: relative;
    z-index: 1;
    overflow: hidden;

    >.container {
        @media screen and (min-width: 1500px) {
            max-width: 1400px;
        }

        @media screen and (min-width: 1700px) {
            max-width: 1600px;
        }
    }

    &__txt {
        padding: 220px 0 260px;

        @include xxl {
            padding: 190px 0 230px;
        }

        @include xl {
            padding: 160px 0 200px;
        }

        @include lg {
            padding: 130px 0 170px;
        }

        @include md {
            padding: 80px 0 170px;
        }

        @include xs {
            padding: 60px 0 170px;
        }
    }

    &__sub-title {
        color: rgb(255 255 255 / 70%);
        margin-bottom: 13px;

        &::before {
            background-color: var(--white-f);
        }
    }

    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 66px;
        font-weight: 800;
        line-height: 112.121%;
        margin-bottom: 22px;

        @media screen and (min-width: 1500px) {
            font-size: 64px;
        }

        @media screen and (max-width: 1499px) {
            font-size: 60px;
        }

        @include xl {
            font-size: 50px;
        }

        @include lg {
            font-size: 42px;
        }

        @include sm {
            font-size: 38px;
        }

        @include xs {
            font-size: 32px;
        }

        @include xxs {
            font-size: 30px;
        }
    }

    &__descr {
        color: rgba(255, 255, 255, .7);
        font-family: var(--mulish);
        font-size: 16px;
        font-weight: 400;
        line-height: 162.5%;
        width: 75%;
        margin-bottom: 42px;

        @include md {
            width: 80%;
            margin-bottom: 37px;
        }

        @include sm {
            width: 100%;
            margin-bottom: 32px;
        }

        @include xxs {
            margin-bottom: 22px;
        }
    }

    .rv-8-def-btn {
        border: 1px solid rgb(255 255 255 / 18%);
        background-color: transparent;

        &:hover {
            color: var(--white-f);
            background-color: #1C74E9;
            border-color: #1C74E9;
        }
    }

    &__img {
        position: relative;
        z-index: -1;

        @include md {
            position: absolute;
            bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            border-radius: 580px;
            background: #0CCACE;
            filter: blur(250px);
            width: 580px;
            height: 580px;
            z-index: -3;
        }

        img {
            max-width: 786px;
            margin-left: -235px;

            @include xl {
                max-width: 726px;
                margin-left: -205px;
            }

            @include lg {
                max-width: 590px;
                margin-left: -175px;
            }

            @include md {
                margin-left: 0;
            }

            @include sm {
                margin-left: -175px;
            }
        }
    }

    &__vector {
        position: absolute;
        z-index: -1;

        &--1 {
            bottom: 92px;
            left: 0;
        }

        &--2 {
            top: 112px;
            left: 108px;
        }

        &--3 {
            bottom: 0;
            right: 0;
        }
    }

    &__form-area {
        background-color: #002A5B;
        padding: 45px 40px 50px;
        width: max-content;
        max-width: 100%;
        margin-left: auto;

        @include xl {
            padding: 35px 30px 40px;
        }

        @include lg {
            padding: 25px 25px 30px;
        }

        h5 {
            color: var(--white-f);
            font-family: var(--mulish);
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 22px;

            @include lg {
                font-size: 22px;
                margin-bottom: 17px;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 330px;

        @include xl {
            width: 270px;
        }

        @include lg {
            width: auto;
        }

        select,
        input {
            border: 1px solid rgba(255, 255, 255, 0.18);
            margin-bottom: 14px;
            color: var(--white-f);
            padding: 9px 25px;
            color-scheme: dark;
            color: var(--white-f);
            font-family: var(--mulish);
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;

            @include lg {
                padding: 7px 15px;
            }

            &::placeholder {
                color: rgb(255 255 255 / 70%);
            }
        }

        select:focus,
        select:focus-visible {
            outline: none;

            option {
                background-color: #002A5B;
            }
        }


        .rv-3-def-btn {
            background-color: #1C74E9;
            border-color: #1C74E9;

            @include lg {
                height: 37px;
                line-height: 34px;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }
}

// INDEX-9 BANNER 
.rv-9-banner {
    position: relative;

    &-slide {
        background: url(../../img/rv-9-banner-1.jpg) no-repeat center center;
        background-size: cover;
        padding: 243px 0 250px;

        @include xl {
            padding: 193px 0 200px;
        }

        @include lg {
            padding: 143px 0 150px;
        }

        @include md {
            padding: 123px 0 130px;
        }

        @include sm {
            padding: 103px 0 110px;
        }

        @include xs {
            padding: 88px 0 105px;
        }

        @include xxs {
            padding: 78px 0 95px;
        }

        &--2 {
            background-image: url(../../img/rv-9-banner-2.jpg);
        }

        &--3 {
            background-image: url(../../img/rv-9-banner-3.jpg);
        }

        &.swiper-slide-active {
            .rv-9-banner__txt::before {
                animation: flip-in-ver-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
            }

            .rv-9-banner-btn {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &__txt {
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            z-index: -1;
            position: absolute;
            width: calc(100% - 220px);
            height: calc(100% + 263px);
            left: -140px;
            border: 1px solid rgba(101, 171, 3, 0.50);
            opacity: 0.6;
            background: #081C15;
            backdrop-filter: blur(2px);
            top: 50%;
            translate: 0 -50%;
        }
    }

    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 75px;
        font-weight: 700;
        line-height: 106.667%;
        margin-bottom: 48px;

        @include xl {
            font-size: 65px;
        }

        @include lg {
            font-size: 60px;
            margin-bottom: 38px;
        }

        @include md {
            font-size: 52px;
        }

        @include sm {
            font-size: 45px;
            margin-bottom: 30px;
        }

        @include xs {
            font-size: 40px;
            margin-bottom: 25px;
        }

        @include xxs {
            font-size: 35px;
            margin-bottom: 20px;
        }
    }

    &__sub-title {
        color: var(--white-f);
        border-color: rgba(255, 255, 255, 0.20);
        margin-bottom: 27px;

        @include sm {
            margin-bottom: 22px;
        }

        @include xs {
            margin-bottom: 17px;
        }

        @include xxs {
            margin-bottom: 12px;
        }

        &::before {
            background-color: var(--white-f);
        }
    }

    &-btn {
        background-color: #65AB03;
        border-color: #65AB03;
        height: 54px;
        line-height: 50px;
        padding: 0 42px;
        font-weight: 700;
        border-radius: 30px;
        transform: translateY(100%);
        opacity: 0;
        transition: 0.4s ease, transform 2.2s ease 0.2s, opacity 2.2s ease 0.2s;

        @include lg {
            height: 48px;
            line-height: 45px;
            padding: 0 32px;
        }

        @include sm {
            height: 45px;
            line-height: 42px;
            padding: 0 27px;
        }

        @include xxs {
            height: 40px;
            line-height: 38px;
            padding: 0 23px;
        }
    }

    .rv-9-slider-dots {
        position: absolute;
        right: 80px;
        top: 50%;
        translate: 0 -50%;
        z-index: 2;
        left: auto;
        flex-direction: column;
        width: max-content;

        @include md {
            right: 50px;
        }

        @include sm {
            right: 20px;
        }

        @include xxs {
            flex-direction: row;
            top: auto;
            bottom: 10px;
            right: 50%;
            translate: 50% 0;
        }
    }
}

@keyframes flip-in-ver-left {
    0% {
        transform: rotateY(80deg);
        opacity: 0;
    }

    100% {
        transform: rotateY(0);
        opacity: 0.6;
    }
}

// INDEX-10 BANNER 
.rv-10-banner {
    text-align: center;

    &__slide {
        padding: 240px 0 283px;
        background: url(../../img/rv-10-banner-1.jpg) no-repeat center center;
        background-size: cover;

        @include xl {
            padding: 180px 0 223px;
        }

        @include lg {
            padding: 160px 0 193px;
        }

        @include md {
            padding: 140px 0 163px;
        }

        @include sm {
            padding: 100px 0 123px;
        }

        &--2 {
            background-image: url(../../img/rv-10-banner-2.jpg);
        }

        &--3 {
            background-image: url(../../img/rv-10-banner-3.jpg);
        }

        &.swiper-slide-active {
            .rv-10-banner__btns {
                >* {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    &__sub-title {
        color: var(--white-f);
        font-size: 22px;
        margin-bottom: 26px;

        @include md {
            margin-bottom: 18px;
        }

        @include xs {
            font-size: 20px;
        }

        .styled {
            background-color: #EFC329;
            color: var(--white-f);
            padding: 0 3px;
        }
    }

    &__title {
        color: var(--white-f);
        font-size: 70px;
        font-weight: 800;
        line-height: 114.286%;
        margin-bottom: 55px;

        @include xl {
            font-size: 60px;
            margin-bottom: 40px;
        }

        @include lg {
            font-size: 50px;
            margin-bottom: 30px;
        }

        @include md {
            font-size: 40px;
            margin-bottom: 20px;
        }

        @include sm {
            font-size: 34px;
        }

        @include xs {
            font-size: 32px;
        }

        @include xxs {
            font-size: 28px;
        }
    }

    &__btns {
        display: flex;
        gap: 15px;
        justify-content: center;

        >* {
            opacity: 0;
            transform: translateY(60%);
            transition: 0.4s ease, opacity 1.4s ease 0.5s, transform 1.4s ease 0.5s;

            &:last-child {
                transition: 0.4s ease, opacity 1.4s ease 0.8s, transform 1.4s ease 0.8s;
            }
        }
    }

    &__btn {
        &:hover {
            color: var(--white-f);
        }

        &--2 {
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 0.20);
            backdrop-filter: blur(5px);
            border: none;
            color: var(--white-f);

            &:hover {
                background-color: var(--white-f);
                color: var(--white-f);
            }
        }
    }

    .rv-7-slider-nav {
        z-index: 1;

        @include sm {
            bottom: 20px;
            position: absolute;
            top: auto;
            translate: -50% 0;
            width: max-content;
        }

        button {
            border-radius: 6px;

            @include xxs {
                width: 40px;
                height: 40px;
                line-height: 37px;
            }
        }
    }
}

.rv-10-colored {
    color: #EFC329;
}

// INDEX-11 BANNER 
.rv-11-banner {
    &__slide {
        background: url(../../img/rv-11-banner-1.jpg) no-repeat center center;
        background-size: cover;
        padding: 260px 0 285px;

        @include xxl {
            padding: 190px 0 215px;
        }

        @include lg {
            padding: 160px 0 195px;
        }

        @include md {
            padding: 110px 0 145px;
        }

        @include sm {
            padding: 110px 0 115px;
        }

        @include xxs {
            padding: 90px 0 115px;
        }

        &--2 {
            background-image: url(../../img/rv-11-banner-2.jpg);
        }

        &--3 {
            background-image: url(../../img/rv-11-banner-3.jpg);
        }

        .cell {
            // transform: translateX(-100%);
        }

        &.swiper-slide-active {
            .cell {
                transform: translateX(100%);
                transition: 1.5s ease;

                &:nth-child(2) {
                    // transition-delay: 0.2s;
                    transform: translateX(-100%);
                    // transform: translate();
                }

                &:nth-child(3) {
                    // transition-delay: 0.4s;
                }
            }
        }

        // &.swiper-slide-active {
        //     opacity: 0.3;
        // }

        &.splitting.cells {
            // visibility: visible;
        }
    }

    .splitting .cell-grid {
        // z-index: -1;
    }

    &__txt {
        text-align: center;
        color: var(--white-f);
    }

    &__sub-title {
        color: var(--white-f);

        span {
            background-color: var(--white-f);

            &::before,
            &::after {
                background-color: var(--white-f);
                opacity: 90%;
            }
        }
    }

    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 60px;
        font-weight: 700;
        line-height: 123.333%;
        margin-bottom: 50px;

        @include xl {
            font-size: 50px;
            margin-bottom: 40px;
        }

        @include lg {
            margin-bottom: 30px;
        }

        @include md {
            font-size: 40px;
        }

        @include sm {
            font-size: 32px;
        }

        @include xxs {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }

    .rv-7-slider-nav {
        z-index: 1;

        @include xs {
            position: absolute;
            left: 50%;
            translate: -50% 0;
            bottom: 20px;
            top: auto;
        }

        button {
            border-radius: 14px 0;

            &:last-child {
                border-radius: 0 14px;
            }
        }
    }

    .rv-11-def-btn {
        background-color: var(--white-f);
        border-color: var(--white-f);
        color: var(--white-f);
        font-weight: 700;
    }
}

@keyframes rv-11-slide-right {
    0% {
        right: -10px;
    }

    50% {
        border-radius: 50%;
    }

    100% {
        right: 100%;
        border-radius: 0;
    }
}

@keyframes rv-11-slide-left {
    0% {
        left: -10px;
    }

    50% {
        border-radius: 50%;
    }

    100% {
        left: 100%;
        border-radius: 0;
    }
}

// INDEX-12 BANNER 
.rv-12-banner {
    position: relative;

    &__slide {
        background: url(../../img/rv-12-banner-bg-1.jpg) no-repeat center center;
        background-size: cover;
        padding: 270px 0 317px;

        @include xl {
            padding: 200px 0 247px;
        }

        @include lg {
            padding: 180px 0 217px;
        }

        @include md {
            padding: 160px 0 197px;
        }

        @include sm {
            padding: 140px 0 207px;
        }

        @include xxs {
            padding: 110px 0 197px;
        }

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            background-color: rgb(5 5 5 / 64%);
        }

        .rv-10-section__sub-title {
            margin-bottom: 10px;
        }

        .rv-11-banner__title {
            font-size: 70px;
            line-height: 114.286%;
            margin-bottom: 40px;

            @include xl {
                font-size: 60px;
                margin-bottom: 30px;
            }

            @include lg {
                font-size: 50px;
            }

            @include md {
                font-size: 40px;
                margin-bottom: 20px;
            }

            @include sm {
                font-size: 35px;
                margin-bottom: 15px;
            }

            @include xs {
                font-size: 32px;
            }

            @include xxs {
                font-size: 28px;
            }
        }

        &--2 {
            background-image: url(../../img/rv-12-banner-bg-2.jpg);
        }

        &--3 {
            background-image: url(../../img/rv-12-banner-bg-3.jpg);
        }
    }

    &__btn {
        padding: 0 45px;
        background: none;
        border: 1px solid rgb(255 255 255 / 24%);
        height: 46px;
        line-height: 42px;

        &:hover {
            color: var(--white-f);
        }
    }


    // socials
    .rv-3-banner__socials {
        @include sm {
            left: 50%;
            flex-direction: row;
            translate: -50% 0;
            bottom: 100px;
            top: auto;
            width: max-content;
        }

        h6 {
            @include sm {
                writing-mode: horizontal-tb;
            }

            &::before {
                @include sm {
                    top: 50%;
                    left: calc(100% + 8px);
                    translate: 0 -50%;
                    height: 2px;
                    width: 14px;
                }
            }
        }

        ul {
            @include sm {
                flex-direction: row;
            }
        }

        a {
            &:hover {
                background-color: #003c8b;
                border-color: #003c8b;
            }
        }
    }
}

// pagination
.swiper-horizontal>.rv-12-banner__dots {
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    top: 50%;
    left: auto;
    right: 40px;
    translate: 0 -50%;
    z-index: 1;
    bottom: auto;
    width: max-content;

    @include md {
        right: 20px;
    }

    @include sm {
        gap: 20px;
        left: 50%;
        top: auto;
        bottom: 65px;
        flex-direction: row;
        translate: -50% 0;
    }

    .swiper-pagination-bullet {
        width: auto;
        height: auto;
        line-height: 1;
        background: none;
        color: #707070;
        font-family: var(--mulish);
        font-size: 15px;
        font-weight: 700;
        position: relative;
        opacity: 1;
        writing-mode: vertical-lr;
        transition: 0.4s ease;

        @include sm {
            margin-bottom: 0;
            writing-mode: horizontal-tb;
            margin-right: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            translate: -50% 0;
            height: 0px;
            width: 1px;
            background-color: var(--white-f);
            transition: 0.4s ease;

            @include sm {
                width: 0;
                height: 1px;
                left: calc(100% + 5px);
                top: 50%;
                translate: 0 -50%;
            }
        }

        &-active {
            color: var(--white-f);
            margin-bottom: 100px;
            scale: 1.46;

            @include sm {
                margin-right: 70px;
                margin-bottom: 0;
            }

            &::before {
                height: 69px;

                @include sm {
                    width: 40px;
                    height: 1px;
                    left: calc(100% + 5px);
                    top: 50%;
                    translate: 0 -50%;
                }
            }
        }
    }
}

// INDEX-13 BANNER
.rv-13-banner {
    background-color: #F6F4F2;

    .container {
        @media screen and (min-width: 1400px) {
            max-width: 1600px;
        }
    }

    &__txt {
        position: relative;
        color: var(--white-f);
        margin: 135px 0;
        z-index: 1;

        @include md {
            margin: 115px 0;
        }

        @include sm {
            margin: 60px 0;
            text-align: center;
        }

        @include xs {
            margin: 60px 0 40px;
        }

        .rv-3-def-btn {
            background-color: #AB8E66;
            border-color: #AB8E66;

            &:hover {
                background-color: transparent;
            }
        }
    }

    .rv-10-section__sub-title {
        margin-bottom: 15px;
    }

    &__title {
        font-family: var(--mulish);
        font-size: 80px;
        font-weight: 700;
        line-height: 95%;
        margin-bottom: 35px;

        @include xxl {
            margin-bottom: 25px;
            font-size: 75px;
        }

        @include xl {
            font-size: 65px;
        }

        @include lg {
            font-size: 55px;
        }

        @include md {
            font-size: 50px;
        }

        @include sm {
            font-size: 45px;
            margin-bottom: 20px
        }

        @include xs {
            font-size: 35px;
            margin-bottom: 15px;
        }

        @include xxs {
            font-size: 30px;
        }
    }

    &__short-descr {
        font-family: Oldenburg;
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 56px;

        @include xxl {
            margin-bottom: 46px;
        }

        @include lg {
            margin-bottom: 36px;
            font-size: 22px;
        }

        @include sm {
            margin-bottom: 26px;
        }

        @include xxs {
            margin-bottom: 20px;
            font-size: 18px;
        }

        .styled {
            position: relative;
            background: url(../../img/rv-13-banner-vector-4.png) no-repeat 10% center;
            background-size: contain;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        @include sm {
            justify-content: center;
            margin-bottom: 60px;
            gap: 0;
        }
    }

    &__img img {
        height: 700px;

        @include xxl {
            height: 640px;
        }

        @include xl {
            height: 575px;
        }

        @include lg {
            height: 540px;
        }

        @include md {
            display: none;
        }
    }

    &-prod-col {
        width: 270px;
        display: flex;
        flex-direction: column;
        gap: 158px;
        position: relative;

        @include md {
            gap: 78px;
        }

        @include sm {
            gap: 38px;
        }

        @include xxs {
            gap: 28px;
        }

        .rv-13-banner__vectors {
            top: -45px;
            right: -70px;

            img {
                position: static;
            }
        }
    }

    &-prod {
        color: var(--white-f);
        font-family: var(--mulish);
        text-align: center;
        opacity: 0 !important;
        pointer-events: none;

        &.swiper-slide-active {
            opacity: 1 !important;
            pointer-events: all;
        }

        &__img {
            width: 270px;
            height: 270px;
            background-color: var(--white-f);
            border: 1px solid rgb(5 5 5 / 14%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 26px;
        }

        &__title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 3px;

            a:hover {
                color: #AB8E66;
            }
        }

        &__brand {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.8;

            a {
                color: var(--white-f);
            }
        }
    }

    &__vectors {
        img {
            position: absolute;
            pointer-events: none;
            animation: rotate-center 3s ease-in-out infinite both;
            z-index: -1;

            &.vector-1 {
                top: -18px;
                left: 250px;
            }

            &.vector-2 {
                right: 70px;
                bottom: 60px;
            }
        }
    }
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
        scale: 1;
    }

    33% {
        transform: rotate(90deg);
        scale: 1;
    }

    66% {
        transform: rotate(90deg);
        scale: 0;
    }

    100% {
        transform: rotate(90deg);
        scale: 1;
    }
}

// INDEX-14 BANNER
.rv-14-banner {
    background: url(../../img/rv-14-banner-bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 250px 0 230px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include lg {
        padding: 230px 0 210px;
    }

    @include md {
        padding: 210px 0 190px;
    }

    @include sm {
        padding: 220px 0 190px;
    }

    @include xs {
        padding: 240px 0 190px;
    }

    @include xxs {
        padding: 260px 0 190px;
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: #174E5E;
        mix-blend-mode: multiply;
    }

    &__sub-title {
        color: var(--white-f);
        margin-bottom: 20px;
    }

    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 90px;
        font-weight: 800;
        line-height: 106.667%;
        margin-bottom: 45px;

        @include xxl {
            font-size: 80px;
        }

        @include xl {
            font-size: 70px;
        }

        @include lg {
            font-size: 60px;
        }

        @include md {
            font-size: 50px;
            margin-bottom: 30px;
        }

        @include sm {
            font-size: 40px;
            margin-bottom: 25px;
        }

        @include xs {
            font-size: 35px;
        }

        @include xxs {
            font-size: 30px;
        }
    }

    .rv-14-def-btn {
        &:hover {
            color: var(--white-f);
            border-color: var(--white-f);
        }
    }

    .wave {
        background: url(../../img/wave-2.svg) repeat-x;
        position: absolute;
        bottom: -77px;
        width: 6400px;
        height: 198px;
        animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        z-index: 2;
    }

}

@keyframes wave {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1600px;
    }
}

// INDEX-15 BANNER
.rv-15-banner_section {
    position: relative;
    z-index: 1;

    .swiper-notification {
        display: none;
    }

    .rv-15-banner_slider_prev {
        left: 2%;
        font-size: 20px;
        z-index: 1;
        top: 50%;
        position: absolute;
        color: #FFFFFF;
        border: 1px solid #ddd;
        background: transparent;
        transition: 0.5s;
        height: 50px;
        width: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: #F35B04;
            border: 1px solid transparent;
        }
    }

    .rv-15-banner_slider_next {
        right: 2%;
        font-size: 20px;
        z-index: 1;
        top: 50%;
        position: absolute;
        color: #FFFFFF;
        border: 1px solid #ddd;
        padding: 10px 10px;
        background: transparent;
        transition: 0.5s;
        height: 50px;
        width: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: #F35B04;
            border: 1px solid transparent;
        }

        @media #{$xxl} {}

        @media #{$xl,$lg} {}

        @media #{$lg} {}
    }

    .rv-15-banner_slider_navigations {
        @media #{$md,$sm,$mobile, $sm-mobile} {
            display: none;
        }
    }

    .rv-15-single_slide {
        align-items: center;
        position: relative;
        z-index: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        // background-image: url('../../img/slider/slider_bg_6.png');

        &::after {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: -1;
        }

        .rv-15-slider_content {
            padding: 200px 50px;

            @media #{$xl,$lg} {
                padding-left: 150px;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                padding: 120px 0;
            }

            .rv-15-banner_button_area {
                display: flex;
                align-items: center;
                column-gap: 20px;
                margin-bottom: 40px;

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    margin-bottom: 10px;
                }

                .rv_6_banner_content_play_btn {
                    color: #FFFFFF;

                    i {
                        background: #FFFFFF;
                        color: #081C15;
                        height: 54px;
                        width: 54px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 54px;
                        margin-right: 15px;
                        animation: pulse 1.5s linear infinite;
                        transition: all 0.4s ease;

                        &:hover {
                            background: #081C15;
                            color: #FFFFFF;
                        }
                    }

                }
            }

            .rv-15-devider_line {
                height: 1px;
                width: 300px;
                background: rgba(255, 255, 255, 0.3);
                margin: 40px 0 50px 0;

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    margin-bottom: 30px;
                }
            }

            .rv-15-slider_subtitle {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                opacity: 0.5;
                text-transform: uppercase;
                padding-bottom: 10px;
                font-family: var(--mulish);
            }

            .rv-15-slider_title {
                color: #FFFFFF;
                font-weight: 600;
                font-size: 70px;
                font-family: var(--mulish);

                @media #{$xl,$md,$sm} {
                    font-size: 50px;
                    margin-bottom: 50px;
                }

                @media #{$md,$sm} {
                    margin-bottom: 100px;
                }

                @media #{$mobile,$sm-mobile} {
                    font-size: 40px;
                    padding-bottom: 100px;
                }

                @media #{$sm-mobile} {
                    font-size: 30px;
                    margin-bottom: 30px;
                }

            }

        }

        .rv-15-slider_text_box {
            position: absolute;
            right: 20%;
            background: var(--white-f);
            height: 200px;
            width: 500px;
            bottom: 0;
            padding: 60px 70px;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                height: 180px;
                width: auto;
                padding: 50px 40px;
                left: 0;
                text-align: left;
            }

            &::after {
                position: absolute;
                content: "";
                left: 14%;
                top: 20%;
                height: 1px;
                background: #FFFFFF;
                width: 80px;

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    left: 9%;
                }
            }

            p {
                font-family: var(--mulish);
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }

    .rv-15-single_slide.swiper-slide-active {
        .rv-15-slider_title {
            animation: fadeInUp 1.5s;
        }

        .rv-15-slider_subtitle {
            animation: fadeInUp 1s;
        }

        .rv-15-banner_button_area {
            animation: fadeInUp 1s;
        }

        .rv-15-slider_text_box {
            animation: fadeInUp 1s;
        }

    }

}

// INDEX-16 BANNER
.rv-16-banner_section {
    position: relative;
    z-index: 2;

    .owl-dots {
        position: absolute;
        top: 50%;
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        z-index: 8;
        transform: translatey(-50%);

        @media #{$xxl} {
            right: 20px;
        }

        @media #{$xl,$lg,$md,$sm,$sm-mobile} {
            right: 35px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            right: 15px;
        }

        @media #{$sm-mobile} {
            display: none;
        }

        button.owl-dot {
            background-color: #FFFFFF;
            width: 20px;
            height: 2px;
            transition: 0.4s ease;
            counter-increment: indexNumber;
            position: relative;

            &::before {
                content: "0" counter(indexNumber);
                position: absolute;
                right: calc(100% + 13px);
                top: 50%;
                translate: 0 -50%;
                color: #FFFFFF;
                opacity: 0;
                transition: 0.4s ease;
            }
        }

        button.owl-dot.active {
            width: 50px !important;
            background: #DFE11B !important;

            &::before {
                opacity: 1;
            }

        }
    }

    .rv-16-banner_single_slide {
        padding: 280px 0 200px;
        position: relative;
        z-index: 1;
        background-position: center;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            padding: 100px 0;
        }

        @media #{$sm-mobile} {
            padding: 70px 0 50px 0;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.7;
            z-index: -1;
            background: #020208;
        }

        .rv-16-banner_content {
            @media #{$md,$sm,$mobile,$sm-mobile} {
                text-align: center;
                padding: 0 20px 30px 20px;
            }

            .rv-16-banner_content_heading {
                color: #FFFFFF;
                font-size: 70px;
                font-weight: 700;
                padding-bottom: 30px;
                max-width: 650px;
                font-family: var(--mulish);

                @media #{$xl,$lg} {
                    font-size: 60px;
                }

                @media #{$lg} {
                    font-size: 40px;
                    padding-bottom: 15px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    max-width: 600px;
                    font-size: 50px;
                    padding-bottom: 15px;
                    margin: auto;
                    font-family: var(--mulish);
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 35px;
                }
            }

            .rv-16-banner_content_desc {
                color: #FFFFFF;
                opacity: 50%;
                font-size: 18px;
                font-weight: 500;
                padding-bottom: 40px;
                font-family: var(--mulish);

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    padding-bottom: 25px;
                }
            }

            .rv-16-banner_content_btn {
                @include rv_btn;
                margin-right: 10px;
                font-family: var(--mulish);

                @media #{$lg,$md,$sm, $mobile,$sm-mobile} {
                    padding: 10px 20px;
                }
            }
        }

        .rv-16-banner_image {
            @media #{$md,$sm,$mobile,$sm-mobile} {
                max-width: 500px;
                margin: auto;
                height: auto;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                max-width: 400px;
            }

            @media #{$sm-mobile} {
                max-width: 350px;
            }

        }
    }

    .owl-item.active {
        .rv-16-banner_content_heading {
            animation: fadeInUp 1.5s;
            animation-duration: 1.5s;
        }

        .rv-16-banner_content_desc {
            animation: fadeInUp 1s;
            animation-duration: 1.5s;
        }

        .rv-16-banner_content_btn {
            animation: fadeInUp 1s;
            animation-duration: 2s;
        }

        .rv-16-banner_image {
            animation: fadeInRight 1.5s;
        }

    }

}


// INDEX-17 BANNER 
.rv-17-banner_slider_section {
    position: relative;
    z-index: 2;

    .owl-dots {
        position: absolute;
        top: 50%;
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 8;
        transform: translateY(-50%);

        @media #{$xxxl,$xxl} {
            right: 40px;
        }

        @media #{$xl,$lg,$md,$sm,$sm-mobile} {
            right: 35px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            right: 15px;
        }

        @media #{$sm-mobile} {
            display: none;
        }

        button.owl-dot {
            transition: 0.4s ease;
            counter-increment: indexNumber;
            position: relative;
            margin-bottom: 10px;

            &::before {
                content: "0" counter(indexNumber);
                position: absolute;
                right: calc(100% + 10px);
                top: 74%;
                translate: 0 -50%;
                color: #FFFFFF;
                opacity: 1;
                transition: 0.4s ease;
                transform: rotate(90deg);
            }

            span {
                height: 0;
                width: 2px;
            }
        }

        button.owl-dot.active {
            width: 0px;
            height: 150px;
            transition: 0.4s ease;
            position: relative;
            right: -7px;
            transform: scale(1.5);
        }

        button.owl-dot.active span {
            height: 55px;
            width: 2px;
            background: #FFFFFF;
            left: -20px;
            position: absolute;
            top: 36px;
            z-index: 111;
            display: inline-block;
            transition: 0.4s ease;
        }
    }

    .rv-17-banner_slider {
        padding-top: 160px;
        padding-bottom: 300px;
        position: relative;
        z-index: 1;
        background-position: center;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$lg, $md, $sm,$mobile,$sm-mobile } {
            padding: 150px 0;
        }

        @media #{$sm-mobile } {
            padding: 100px 0;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.5;
            z-index: -1;
            background: #020208;
        }

        .rv-17-banner_content {

            .rv-17-banner_sub_heading {
                color: #FFFFFF;
                font-weight: 600;
                position: relative;
                padding-left: 10px;
                text-transform: uppercase;
                font-family: var(--mulish);

                &::after {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 2px;
                    background: #DFE11B;
                    left: 0;
                    top: 0;
                }
            }

            .rv-17-banner_content_heading {
                font-size: 100px;
                font-weight: 800;
                color: #FFFFFF;
                text-transform: uppercase;
                font-family: var(--mulish);

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 80px;
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 60px;
                }

                @media #{$sm-mobile} {
                    font-size: 40px;
                }

            }

            .rv-17-banner_content_desc {
                color: rgba(255, 255, 255, 0.8);
                font-size: 18px;
                font-weight: 500;
                font-family: var(--mulish);
                margin-bottom: 15px;

                @media #{$sm-mobile} {
                    font-size: 15px;
                }
            }

            .rv-17-banner_content_meta {
                display: flex;
                align-items: center;
                color: #FFFFFF;
                padding-bottom: 30px;
                flex-wrap: wrap;
                gap: 30px;

                @media #{$sm-mobile} {
                    gap: 15px;
                }

                .movi_rating {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                }

                .movi_tag {
                    font-family: var(--mulish);
                    font-weight: 700;
                }

                .movi_quality {
                    p {
                        color: #FFFFFF;
                        margin: 0;
                        font-weight: 600;
                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        padding: 0 0 0 12px;
                        font-family: var(--mulish);

                        span {
                            font-family: var(--mulish);
                            background: #FFFFFF;
                            color: #020208;
                            border-radius: 13px;
                            padding: 0 12px;
                            margin-left: 7px;
                        }
                    }
                }

                .movi_times {
                    p {
                        color: #FFFFFF;
                        margin: 0;
                        font-weight: 600;
                        font-family: var(--mulish);

                        span {
                            border: 1px solid #FFFFFF;
                            font-family: var(--mulish);
                            border-radius: 30px;
                            padding: 0px 12px;
                            margin-left: 5px
                        }
                    }
                }
            }

            .rv-17-banner_content_btn {
                @include rv_btn;
                font-family: var(--mulish);
                text-transform: capitalize;
            }
        }

        .rv-17-video_button_area {
            text-align: right;

            @media #{$md,$sm,$mobile,$sm-mobile} {
                text-align: center;
                margin-top: 140px;
            }

            .rv-17-banner_video_btn {
                @include play_btn;
                background-color: #A8AA04;
                color: #020208;
                position: relative;
                transform: none;
                left: unset;
                top: unset;
                border: none;

                &:hover {
                    background-color: #020208;
                    color: #A8AA04;
                }

                .rv-17-banner_button_top_action {
                    position: absolute;
                    content: "";
                    left: -200px;
                    top: -110px;
                    width: 179px;
                    text-align: center;

                    @media #{$sm-mobile} {
                        left: -128px;
                    }

                    h3 {
                        font-family: var(--mulish);
                        font-size: 20px;
                        font-weight: 700;
                        color: #FFFFFF;
                        margin: 0
                    }

                    span {
                        font-family: var(--mulish);
                        position: absolute;
                        left: 52%;
                    }

                    span img {
                        height: 100px;
                        width: auto;
                    }
                }
            }

        }
    }

    .owl-item.active {
        .rv-17-banner_content_heading {
            animation: fadeInUp 1.5s;
            animation-duration: 1.5s;
        }

        .rv-17-banner_content_desc {
            animation: fadeInUp 1s;
            animation-duration: 1.5s;
        }

        .rv-17-banner_content_meta {
            animation: fadeInUp 1s;
            animation-duration: 1.5s;
        }

        .rv-17-banner_content_btn {
            animation: fadeInUp 1s;
            animation-duration: 2s;
        }

        .rv-17-banner_sub_heading {
            animation: fadeInRight 1.2s;
            animation-duration: 1s;
        }
    }
}

// INDEX-18 BANNER
.rv-18-banner_main_area {
    .rv-18-banner_slider {
        position: relative;
        margin: 0 80px;

        @include xxl {
            margin: 0;
        }

        .rv-18-banner_single_slider {
            z-index: 1;
            position: relative;
            padding: 260px 0;

            @media #{$sm,$mobile,$sm-mobile} {
                padding: 150px 0;
            }

            &::after {
                position: absolute;
                content: "";
                inset: 0;
                opacity: 0.9;
                background: linear-gradient(90deg, #142137 0.11%, rgba(20, 33, 55, 0.60) 99.91%);
                z-index: -1;
            }

            h1 {
                color: #FFFFFF;
                font-size: 70px;
                font-weight: 800;
                position: relative;
                z-index: 1;

                @media #{$lg} {
                    font-size: 55px;
                }

                @media #{$md,$sm,$mobile} {
                    font-size: 38px;
                }

                @media #{$sm-mobile} {
                    font-size: 30px;
                }

                span {
                    position: absolute;
                    right: 240px;
                    top: 30px;
                    z-index: -1;
                }
            }

            p {
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 500;
                margin: 0;
                padding-bottom: 40px;

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 16px;
                    padding-bottom: 20px;
                }

                @media #{$sm-mobile} {
                    font-size: 14px;
                }
            }

            .rv-18-banner_content_btn {
                background: #1C74E9;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 700;
                width: 200px;
                height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: all 0.4s ease;
                z-index: 1;

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    margin-bottom: 20px;
                }

                @media #{$sm-mobile} {
                    font-size: 14px;
                    width: 170px;
                    height: 50px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    background-color: rgba(241, 241, 241, 0.3647058824);
                    width: 0;
                    height: 100%;
                    transition: all 0.4s ease;
                    z-index: -1;
                    left: auto;

                }

                &:hover {
                    color: var(--white-f);

                    i {
                        transform: rotate(-45deg);
                    }
                }

                &:hover::before {
                    width: 100%;
                    left: 0;
                }

                i {
                    margin-left: 10px;
                    font-size: 18px;
                    transition: all 0.3s ease;
                }
            }
        }

        .rv-18-banner_slider_next {
            position: absolute;
            right: 200px;
            bottom: 0;
            z-index: 1;
            width: 200px;
            height: 200px;
            padding: 40px 20px;
            backdrop-filter: blur(60px);
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.20);
            background: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(8px);

            @media #{$sm,$mobile} {
                width: 170px;
                height: 150px;
                right: 170px;
            }

            @media #{$sm-mobile} {
                width: 170px;
                height: 137px;
                right: 139px;
            }

            p {
                font-size: 16px;
                font-weight: 700;
                color: #FFFFFF;
                padding: 30px 0;
                margin: 0;
                font-family: var(--mulish);

                @media #{$sm,$mobile} {
                    padding: 15px 0 5px 0;
                    font-size: 13px;
                }

                @media #{$sm-mobile} {
                    line-height: 1.5;
                    padding: 15px 0 5px 0;
                    font-size: 13px;
                }
            }

            i {
                color: rgba(198, 200, 212, 0.4901960784);
                font-size: 30px;
            }

            .swiper-pagination {
                position: absolute;
                color: #c6c8d47d;
                top: 15px;
                left: 19px;
                text-align: left;
                font-size: 23px;
                font-weight: 700;
            }

            span.swiper-pagination-bullet {
                background: none;
                width: 30px;
                opacity: 0;
                position: absolute;
                top: 20px;

                @media #{$sm,$mobile,$sm-mobile} {
                    top: 10px;
                }
            }

            .swiper-pagination-bullet-active {
                color: rgba(255, 255, 255, 0.3);
                opacity: 1 !important;
                font-size: 25px;
                font-weight: 700;
            }
        }

        .rv-18-banner_slider_bottom_area {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 200px;
            height: 200px;

            @media #{$sm,$mobile} {
                width: 170px;
                height: 150px;
            }

            @media #{$sm-mobile} {
                width: 140px;
                height: 137px;
            }

            .rv-18-banner_slider_bottom_image {
                width: 200px;
                height: 200px;

                @media #{$sm,$mobile} {
                    width: 170px;
                    height: 150px;
                }

                @media #{$sm-mobile} {
                    width: 140px;
                    height: 137px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

// INDEX-19 BANNER
.rv-19-banner_section {
    position: relative;
    z-index: 2;

    .owl-dots {
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        z-index: 8;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            right: 35px;
        }

        button.owl-dot {
            background-color: rgba(255, 255, 255, 0.3);
            width: 2px;
            height: 40px;
            transition: 0.4s ease;
        }

        button.owl-dot.active {
            background-color: #DFE11B !important;
        }
    }

    .rv-19-banner_slide {
        position: relative;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: scroll;
        height: 100%;

        .rv-19-banner_content {
            padding-left: 50px;

            @media #{$xl,$lg,$md} {
                padding-left: 0;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                padding: 80px 0;
                text-align: center;
            }

            @media #{$sm-mobile} {
                padding: 80px 0;
                text-align: center;
                padding-bottom: 50px;
            }

            .rv-19-banner_content_sub_heading {
                color: #141414;
                background: #DFE11B;
                font-weight: 600;
                font-size: 16px;
                width: 160px;
                height: 26px;
                text-align: center;
                line-height: 26px;
            }

            .rv-19-banner_content_heading {
                color: #FFFFFF;
                font-size: 95px;
                font-weight: 800;
                margin-bottom: 50px;
                font-family: var(--mulish);

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 70px;
                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 60px;
                    margin-bottom: 30px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 47px;
                    margin-bottom: 20px;
                }

                @media #{$sm-mobile} {
                    font-size: 40px;
                    margin-bottom: 20px;
                }
            }

            .rv-19-banner_button {
                font-size: 16px;
                font-weight: 700;
                color: #FFFFFF;
                width: 200px;
                height: 54px;
                border: 1px solid #597B84;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                background: transparent;
                position: relative;
                z-index: 1;
                font-family: var(--mulish);

                @media #{$sm,$mobile,$sm-mobile} {
                    margin: auto;
                }

                .rv-19-banner_content_btn {
                    border-right: 1px solid #597B84;
                    width: 100%;
                    color: #FFFFFF;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: var(--mulish);
                }

                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    background-color: #DFE11B;
                    width: 0;
                    height: 100%;
                    transition: all 0.4s ease;
                    z-index: -1;
                    left: auto;
                }

                &:hover {
                    color: var(--white-f);

                    i {
                        transform: rotate(90deg);
                    }
                }

                &:hover .rv-19-banner_content_btn {
                    color: var(--white-f);
                }

                &:hover::before {
                    width: 100%;
                    left: 0;
                }

                i {
                    height: 54px;
                    width: 54px;
                    align-self: flex-end;
                    text-align: center;
                    line-height: 54px;
                    transform: rotate(45deg);
                    transition: all 0.4s ease;
                }
            }
        }

        .rv-19-banner_image {
            height: 950px;
            width: 500px;

            @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                height: 700px;
                width: 450px;
                margin: auto;
            }

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                height: 600px;
                width: 350px;
                margin: auto;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                height: 530px;
                width: 280px;
                margin: auto;
            }

            @media #{$mobile,$sm-mobile} {
                height: 420px;
                width: 260px;
                margin: auto;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: scale-down;
            }
        }

        .rv-19-banner_socials {
            position: absolute;
            left: 30px;
            top: 50%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            transform: translateY(-50%);

            @media #{$md,$sm,$mobile,$sm-mobile} {
                left: 15px;
            }

            @media #{$sm-mobile} {
                display: none;
            }

            .rv-19-banner_link {
                a {
                    border: 2px solid rgba(255, 255, 255, 0.2);
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background: transparent;
                    color: #FFFFFF;
                    font-size: 18px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    transition: all 0.4s ease;
                    font-family: var(--mulish);

                    &:hover {
                        background: #DFE11B;
                        border: 2px solid transparent;
                        color: #141414;
                    }
                }
            }

            span {
                position: absolute;
                transform: rotate(-90deg);
                bottom: -66px;
                width: 89px;
                left: -25px;
                color: #FFFFFF;

                &::before {
                    content: "";
                    right: -6px;
                    position: absolute;
                    height: 2px;
                    width: 15px;
                    background: #FFFFFF;
                    top: 15px;
                }
            }
        }
    }

    .rv-19-banner_row {
        @media #{$sm,$mobile,$sm-mobile} {
            flex-direction: column-reverse;
        }
    }
}

// INDEX-20 BANNER 
.rv-20-banner_section {
    position: relative;
    z-index: 2;

    .rv-20-banner_slide_button_area {
        position: absolute;
        right: 15%;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        row-gap: 7px;

        @media #{$lg,$md} {
            right: 9%;
        }

        @media #{$md,$sm,$mobile,$sm-mobile} {
            right: 6%;
        }

        @media #{$sm-mobile} {
            display: none;
        }

        .rv-20-banner_slide_button_prev {
            background: #FFFFFF;
            height: 53px;
            width: 53px;
            text-align: center;
            line-height: 53px;
            color: #000;
            rotate: -45deg;
            transition: all 0.4s ease;

            @media #{$sm,$mobile,$sm-mobile} {
                height: 45px;
                width: 45px;
                line-height: 45px;
            }

            i {
                rotate: 45deg;
            }

            &:hover {
                background: #003d8b;
                color: #FFFFFF;
            }
        }

        .rv-20-banner_slide_button_next {
            background: #FFFFFF;
            height: 53px;
            width: 53px;
            text-align: center;
            line-height: 53px;
            color: #000;
            rotate: -45deg;
            transition: all 0.4s ease;
            margin-left: 20px;

            @media #{$sm,$mobile,$sm-mobile} {
                height: 45px;
                width: 45px;
                line-height: 45px;
            }

            i {
                rotate: 45deg;
            }

            &:hover {
                background: #003d8b;
                color: #FFFFFF;
            }
        }

    }

    .rv-20-banner_slide {
        background: url(../../img/slider/home-6-banner-bg-1.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            opacity: 0.8;
            background: linear-gradient(90deg, #081C15 35.94%, rgba(8, 28, 21, 0.00) 82.81%);
        }

        &-2 {
            background-image: url(../../img/slider/home-6-banner-bg-2.jpg);
        }

        .rv-20-banner_content {
            padding: 100px 0;

            @media #{$lg} {
                padding: 150px 0;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                padding: 130px 0;
            }

            @media #{$mobile,$sm-mobile} {
                padding: 100px 0;
            }

            .rv-20-banner_content_sub_heading {
                color: #FFFFFF;
                font-weight: 500;
                font-size: 16px;
                width: 250px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;
                font-family: var(--mulish);

                @media #{$mobile,$sm-mobile} {
                    font-size: 14px;
                    width: 200px;
                    height: 36px;
                    margin-bottom: 30px;
                }

                span {
                    height: 5px;
                    width: 5px;
                    background: #FFFFFF;
                    border-radius: 50%;
                }
            }

            .rv-20-banner_content_heading {
                color: #FFFFFF;
                font-size: 75px;
                font-weight: 800;
                margin-bottom: 60px;
                font-family: var(--mulish);

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 60px;
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 45px;
                    margin-bottom: 50px;
                }

                @media #{$mobile,$sm-mobile} {
                    font-size: 35px;
                    margin-bottom: 35px;
                }

                @media #{$sm-mobile} {
                    font-size: 30px;
                    margin-bottom: 35px;
                }
            }

            .rv-20-banner_button_area {
                display: flex;
                align-items: center;
                column-gap: 20px;

                @media #{$sm-mobile} {
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 30px;
                }

                .rv-20-banner_content_btn {
                    background: #FFFFFF;
                    color: #081C15;
                    font-size: 16px;
                    font-weight: 700;
                    width: 200px;
                    height: 54px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    transition: all 0.4s ease;
                    z-index: 1;
                    font-family: var(--mulish);

                    @media #{$sm,$mobile,$sm-mobile} {
                        width: 160px;
                        height: 50px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        background-color: rgba(241, 241, 241, 0.3647058824);
                        width: 0;
                        height: 100%;
                        transition: all 0.4s ease;
                        z-index: -1;
                        left: auto;
                    }

                    &:hover {
                        color: #FFFFFF;
                        background: #003d8b;
                    }

                    &:hover::before {
                        width: 100%;
                        left: 0;
                    }

                    i {
                        margin-left: 10px;
                        font-size: 18px;
                        transition: all 0.3s ease;
                    }
                }

                .rv-20-banner_content_play_btn {
                    color: #FFFFFF;

                    i {
                        background: #FFFFFF;
                        color: #081C15;
                        height: 54px;
                        width: 54px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 54px;
                        margin-right: 15px;
                        animation: pulse 1.5s linear infinite;
                        transition: all 0.4s ease;

                        &:hover {
                            background: #003d8b;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}


// INDEX-21 BANNER
.rv-21-banner_section {
    position: relative;
    z-index: 2;

    .rv-21-banner_slide_button_area {
        position: absolute;
        z-index: 1;
        bottom: 35px;
        transform: translateX(-50%);
        display: flex;
        column-gap: 20px;
        left: 50%;
        width: 230px;
        justify-content: center;

        span {
            color: #0E1428;
            text-transform: uppercase;
            font-weight: 600;
        }

        .rv-21-banner_slide_button_prev {
            opacity: 0.5;
            transition: all 0.3s ease;

            &:hover {
                opacity: 1;
            }
        }

        .rv-21-banner_slide_button_next {
            opacity: 0.5;
            transition: all 0.3s ease;

            &:hover {
                opacity: 1;
            }
        }

    }

    .rv-21-banner_slide {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: scroll;
        padding-top: 150px;

        @media #{$xxl,$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
            padding-top: 120px;
        }

        @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
            padding-top: 100px;
        }

        @media #{$md,$sm,$mobile,$sm-mobile} {
            padding-top: 80px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            padding-top: 30px;
            padding-bottom: 100px;
        }

        .rv-21-banner_content {
            padding-top: 120px;

            @media #{$xxl,$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                padding-top: 100px;
            }

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                padding-top: 70px;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                padding-top: 50px;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                padding-top: 50px;
                text-align: center;
                padding-bottom: 50px;
            }

            .rv-21-banner_content_heading {
                color: #0E1428;
                font-size: 65px;
                font-weight: 700;
                margin-bottom: 30px;
                font-family: var(--mulish);

                @media #{$xl,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 60px;
                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 50px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 38px;
                    margin-bottom: 15px;
                }

                @media #{$mobile,$sm-mobile} {
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                @media #{$sm-mobile} {
                    font-size: 25px;
                    margin-bottom: 15px;
                }
            }

            .rv-21-banner_content_desc {
                color: rgba(20, 20, 40, 0.6);
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 30px;
                font-family: var(--mulish);

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 16px;
                }

                @media #{$sm-mobile} {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }

            .rv-21-banner_button_area {
                width: 180px;
                height: 90px;
                position: relative;
                cursor: pointer;

                @media #{$sm,$mobile,$sm-mobile} {
                    margin: auto;
                }

                &:hover .rv-21-bestdeal_content_btn {
                    color: #0E1428;
                    letter-spacing: 1px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }

                .rv-21-banner_content_btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #FFFFFF;
                    font-size: 16px;
                    font-family: var(--mulish);
                    font-weight: 700;
                    transition: all 0.4s ease;
                }
            }
        }

        .rv-21-banner__iamge {
            height: 700px;
            width: 100%;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                height: 580px;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                height: 450px;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                height: 400px;
            }

            @media #{$mobile,$sm-mobile} {
                height: 350px;
            }

            @media #{$sm-mobile} {
                height: 280px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

// INDEX-22 BANNER
.rv-22-banner_section {
    position: relative;
    z-index: 2;

    .owl-dots {
        position: absolute;
        top: 50%;
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 8;
        transform: translateY(-50%);

        @media #{$xxl} {
            right: 20px;
        }

        @media #{$xl,$lg,$md,$sm,$sm-mobile} {
            right: 35px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            right: 15px;
        }

        @media #{$mobile,$sm-mobile} {
            right: 0px;
        }

        @media #{$sm-mobile} {
            display: none;
        }

        button.owl-dot {
            transition: 0.4s ease;
            counter-increment: indexNumber;
            position: relative;

            &::before {
                content: "0" counter(indexNumber);
                position: absolute;
                right: calc(100% + 10px);
                top: 74%;
                translate: 0 -50%;
                color: #FFFFFF;
                opacity: 1;
                transition: 0.4s ease;
                transform: rotate(90deg);
            }

            span {
                height: 0;
                width: 2px;
            }
        }

        button.owl-dot.active {
            width: 0px;
            height: 150px;
            transition: 0.4s ease;
            position: relative;
            right: -7px;
            transform: scale(1.5);
        }

        button.owl-dot.active span {
            height: 55px;
            width: 2px;
            background: #FFFFFF;
            left: -20px;
            position: absolute;
            top: 36px;
            z-index: 111;
            display: inline-block;
            transition: 0.4s ease;
        }
    }

    .rv-22-banner_slide {
        padding: 215px 0;
        position: relative;
        z-index: 1;
        background-position: center;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
            padding: 170px 0;
        }

        @media #{$md,$sm,$mobile,$sm-mobile} {
            padding: 140px 0;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            padding: 130px 0;
        }

        @media #{$mobile,$sm-mobile} {
            padding: 100px 0;
        }

        @media #{$sm-mobile} {
            padding: 80px 0;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background: rgba(4, 14, 31, 0.8);
        }

        .rv-22-banner_content {
            text-align: center;

            .rv-22-banner_sub_heading {
                color: rgba(255, 255, 255, 0.7);
                font-weight: 500;
                position: relative;
                text-transform: uppercase;
                display: inline-block;
                font-size: 16px;
                font-family: var(--mulish);

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 14px;
                }

                .rv8_sub_pre_sty,
                .rv8_sub_pre_nex {
                    content: "";
                    background: #9a9ea5;
                    width: 60px;
                    height: 1px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$sm,$mobile,$sm-mobile} {
                        width: 40px;
                    }

                    @media #{$sm-mobile} {
                        width: 35px;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        height: 8px;
                        width: 8px;
                        left: -15px;
                        background: #9a9ea5;
                        border-radius: 50%;
                        transform: translateY(-50%);
                        animation: slide-right 2.5s linear infinite alternate both;
                        top: 0;

                        @-webkit-keyframes slide-right {
                            0% {
                                left: 0;
                            }

                            100% {
                                left: 100%;
                            }
                        }

                        @keyframes slide-right {
                            0% {
                                left: 0;
                            }

                            100% {
                                left: 100%;
                            }
                        }
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        height: 8px;
                        width: 8px;
                        right: -15px;
                        background: #9a9ea5;
                        border-radius: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        animation: slide-left 2.5s linear infinite alternate both;
                        top: 0;

                        @-webkit-keyframes slide-left {
                            0% {
                                right: 0;
                            }

                            100% {
                                right: 100%;
                            }
                        }

                        @keyframes slide-left {
                            0% {
                                right: 0;
                            }

                            100% {
                                right: 100%;
                            }
                        }
                    }
                }

                .rv8_sub_pre_sty {
                    left: -50%;

                    @media #{$sm,$mobile,$sm-mobile} {
                        left: -45%;
                    }

                    @media #{$sm-mobile} {
                        left: -40%;
                    }
                }

                .rv8_sub_pre_nex {
                    right: -50%;

                    @media #{$sm,$mobile,$sm-mobile} {
                        right: -45%;
                    }

                    @media #{$sm-mobile} {
                        right: -40%;
                    }
                }
            }

            .rv-22-banner_content_heading {
                font-size: 76px;
                font-weight: 700;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-bottom: 60px;
                font-family: var(--mulish);

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 60px;
                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 50px;
                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 40px;
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 35px;
                    margin-bottom: 45px;
                }

                @media #{$mobile,$sm-mobile} {
                    font-size: 30px;
                }

                @media #{$sm-mobile} {
                    font-size: 20px;
                }
            }

            .rv-22-banner_content_btn {
                font-size: 16px;
                font-weight: 700;
                color: #FFFFFF;
                background: #B38D3F;
                padding: 15px 25px;
                border-radius: 30px;
                display: inline-block;
                position: relative;
                z-index: 1;
                overflow: hidden;
                transition: all 0.4s ease;
                font-family: var(--mulish);

                @media #{$sm-mobile} {
                    font-size: 14px;
                }

                &::before {
                    position: absolute;
                    content: "";
                    background-color: #FFFFFF;
                    width: 100%;
                    height: 0%;
                    left: 50%;
                    top: 50%;
                    border-radius: 0px;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    z-index: -1;
                    transition: all 0.4s ease;
                }

                &:hover {
                    color: var(--white-f);
                }

                &:hover::before {
                    height: 400%;
                }
            }
        }

        .rv-22-banner_socials {
            position: absolute;
            left: 30px;
            top: 60%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            transform: translateY(-50%);

            @media #{$mobile,$sm-mobile} {
                left: 10px;
            }

            @media #{$sm-mobile} {
                display: none;
            }

            .rv-22-banner_link {
                a {
                    border: 2px solid rgba(255, 255, 255, 0.2);
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background: transparent;
                    color: #FFFFFF;
                    font-size: 18px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    transition: all 0.4s ease;
                    font-family: var(--mulish);

                    &:hover {
                        background: #B38D3F;
                        border: 2px solid transparent;
                    }
                }
            }

            span {
                position: absolute;
                transform: rotate(90deg);
                top: -84px;
                width: 89px;
                left: -25px;
                color: #FFFFFF;

                &::before {
                    content: "";
                    right: -7px;
                    position: absolute;
                    height: 1px;
                    width: 18px;
                    background: #FFFFFF;
                    top: 12px;
                }
            }
        }

    }
}

// INDEX-23 BANNER
.rv-23-banner_section {
    position: relative;
    z-index: 2;

    .owl-dots {
        position: absolute;
        top: 50%;
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        z-index: 8;
        transform: translateY(-50%);

        @media #{$xxl} {
            right: 20px;
        }

        @media #{$xl,$lg,$md,$sm,$sm-mobile} {
            right: 35px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            right: 15px;
        }

        @media #{$sm-mobile} {
            // display: none;
        }

        button.owl-dot {
            background-color: #FFFFFF;
            width: 20px;
            height: 2px;
            transition: 0.4s ease;
            counter-increment: indexNumber;
            position: relative;

            &::before {
                content: "0" counter(indexNumber);
                position: absolute;
                right: calc(100% + 13px);
                top: 50%;
                translate: 0 -50%;
                color: #FFFFFF;
                opacity: 0;
                transition: 0.4s ease;
            }
        }

        button.owl-dot.active {
            width: 50px !important;
            background: #3BA52D !important;

            &::before {
                opacity: 1;
            }

        }
    }

    .rv-23-banner_slide {
        padding: 260px 0;
        position: relative;
        z-index: 1;
        background-position: center;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
            padding: 150px 0 200px 0;
        }

        @media #{$md,$sm,$mobile,$sm-mobile} {
            padding: 130px 0 160px 0;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            padding: 100px 0 130px 0;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background: linear-gradient(104deg, rgba(0, 81, 161, 0.80) 0%, rgba(0, 68, 136, 0.30) 100%);
        }

        .rv-23-banner_content {
            @media #{$xxl,$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                padding-left: 50px;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                padding-left: 0px;
            }

            .rv-23-banner_content_heading {
                color: #FFFFFF;
                font-size: 59px;
                font-weight: 800;
                margin-bottom: 50px;
                text-transform: uppercase;
                font-family: var(--mulish);

                @media #{$xl,$lg} {
                    font-size: 40px;
                }

                @media #{$lg} {
                    font-size: 40px;
                    margin-bottom: 30px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 30px;
                    margin-bottom: 30px;
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 25px;
                    margin-bottom: 25px;
                }

                @media #{$sm-mobile} {
                    font-size: 22px;
                    margin-bottom: 25px;
                }
            }

            .rv-23-banner_content_sub_heading {
                color: #FFFFFF;
                font-size: 15px;
                font-weight: 700;
                background: #020208;
                padding: 1px 4px;
                display: inline-block;
                font-family: var(--mulish);
            }

            .rv-23-banner_content_btn {
                font-family: var(--mulish);
                height: 54px;
                width: 190px;
                text-align: center;
                display: inline-block;
                line-height: 54px;
                font-size: 16px;
                font-weight: 700;
                background-color: #3BA52D;
                color: #FFFFFF;
                position: relative;
                overflow: hidden;
                transition: all 0.4s ease;
                z-index: 1;

                @media #{$mobile,$sm-mobile} {
                    width: 160px;
                }

                &:hover {
                    color: #020208;

                    &::before {
                        height: 100%;
                        width: 100%;
                        border-radius: 0;
                        top: 0;
                        left: 0;
                        transform: translate(0, 0) scale(1);
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    background: #FFFFFF;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    transition: all 0.4s cubic-bezier(.79, .14, .15, .86);
                    z-index: -1;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                }
            }
        }

        .rv-23-banner_socials {
            position: absolute;
            left: 0;
            top: 50%;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            transform: translateY(-50%);

            @media #{$sm,$mobile,$sm-mobile} {
                bottom: 30px;
                flex-direction: row;
                left: 50%;
                transform: translateX(-50%);
                top: inherit;
                align-items: center;
            }

            .rv-23-banner_link {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 10px;
                align-items: center;

                @media #{$sm,$mobile,$sm-mobile} {
                    flex-direction: row;
                    margin: 0;
                }

                a {
                    border: 2px solid rgba(255, 255, 255, 0.10);
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    background: transparent;
                    color: #FFFFFF;
                    font-size: 18px;
                    text-align: center;
                    transition: all 0.4s ease;
                    font-family: var(--mulish);

                    &:hover {
                        background: #3BA52D;
                        border: 2px solid transparent;
                        color: #FFFFFF;
                    }
                }
            }

            p {
                transform: rotate(-90deg);
                width: 115px;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 700;
                margin: 0;

                @media #{$sm,$mobile,$sm-mobile} {
                    transform: rotate(0deg);
                    order: -1;
                    align-items: center;
                    display: flex;
                }

                span {
                    height: 2px;
                    width: 15px;
                    background: #FFFFFF;
                    margin-left: 5px;
                }
            }
        }
    }
}

// INDEX-24 BANNER 
.rv-24-banner_main_section {
    position: relative;
    overflow: hidden;
    isolation: isolate;

    .rv-24-banner_section {
        position: relative;
        z-index: 2;

        .owl-dots {
            position: absolute;
            bottom: 30%;
            right: 10%;
            display: flex;
            z-index: 8;
            justify-content: center;
            column-gap: 30px;

            @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                bottom: 42%;
                right: 3%;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                bottom: 42%;
                right: 10%;
            }

            @media #{$sm-mobile} {
                bottom: 54%;
                right: 3%;
            }

            button.owl-dot {
                background-color: rgba(255, 255, 255, 0.7);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                transition: 0.4s ease;
                counter-increment: indexNumber;
                position: relative;
                margin-right: 10px;
                position: relative;

                &::before {
                    content: "0" counter(indexNumber);
                    position: absolute;
                    left: calc(100% + 13px);
                    top: 50%;
                    translate: 0 -50%;
                    color: #FFFFFF;
                    opacity: 0;
                    transition: 0.4s ease;
                }
            }

            button.owl-dot.active {
                width: 60px !important;
                height: 1px;
                border-radius: 0;
                top: 3px;

                &::before {
                    opacity: 1;
                }

                span {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.70);
                    position: absolute;
                    left: 0;
                    top: -3px;

                    &::before {
                        position: absolute;
                        height: 35px;
                        width: 35px;
                        content: "";
                        border-radius: 50%;
                        border: 1px solid rgba(255, 255, 255, 0.70);
                        background: transparent;
                        top: -13px;
                        left: -13px;
                    }
                }

            }
        }

        .rv-24-banner_slide {
            position: relative;
            z-index: 1;
            background-position: center;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-size: cover;

            // padding: 200px 0 200px 0;
            &::after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
                background: rgb(2 2 8 / 60%);
            }

            .rv-24-banner_content {
                padding: 200px 0 300px 0;

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    padding: 180px 0 230px 0;

                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    padding: 150px 0 230px 0;

                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    padding: 120px 0 230px 0;
                }

                @media #{$sm,$mobile,$sm-mobile} {
                    padding: 120px 0 350px 0;
                }

                @media #{$mobile,$sm-mobile} {
                    padding: 90px 0 400px 0;
                }

                @media #{$sm-mobile} {
                    padding: 90px 0 575px 0;
                }

                .rv-24-banner_content_sub_heading {
                    color: #FFFFFF;
                    font-size: 18px;
                    font-weight: 600;
                    padding: 1px 4px;
                    display: inline-block;
                    font-family: var(--mulish);

                    @media #{$sm-mobile} {
                        font-size: 16px;
                    }
                }

                .rv-24-banner_content_heading {
                    color: #FFFFFF;
                    font-size: 70px;
                    font-weight: 800;
                    margin-bottom: 60px;
                    text-transform: capitalize;
                    font-family: var(--mulish);

                    @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                        font-size: 60px;
                    }

                    @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                        font-size: 50px;
                        margin-bottom: 40px;
                    }

                    @media #{$md,$sm,$mobile,$sm-mobile} {
                        font-size: 40px;
                    }

                    @media #{$mobile,$sm-mobile} {
                        font-size: 30px;
                        margin-bottom: 30px;
                    }

                    @media #{$sm-mobile} {
                        font-size: 25px;
                        margin-bottom: 30px;
                    }
                }

                .rv-24-banner_button {
                    background: #A58051;
                    color: #FFFFFF;
                    position: relative;
                    font-size: 16px;
                    font-weight: 700;
                    width: 170px;
                    height: 52px;
                    line-height: 52px;
                    border-radius: 26px;
                    transition: all 0.4s ease;

                    &:hover {
                        span {
                            left: 15%;
                        }

                        i {
                            left: calc(100% - 40px);
                        }
                    }

                    i {
                        @include play_btn;
                        color: #020208;
                        background: #FFFFFF;
                        left: -20px;
                        translate: 0 -50%;
                        transition: all 0.5s ease;

                        &:hover {
                            background: #020208;
                            color: #FFFFFF;
                        }
                    }

                    span {
                        position: absolute;
                        left: 35%;
                        transition: all 0.4s ease;
                    }
                }
            }
        }
    }

    .rv-24-room_booking {
        position: absolute;
        bottom: 0;
        content: '';
        z-index: 1111;
        left: 0;
        background: #142226;

        @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
            bottom: 0;
        }

        @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
            bottom: 0;
        }

        @media #{$md,$sm,$mobile,$sm-mobile} {
            bottom: 0;
        }

        .rv-24-form {
            display: flex;
            column-gap: 60px;
            padding: 30px;
            align-items: center;
            flex-wrap: wrap;
            gap: 30px;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                column-gap: 25px;
                padding: 20px;
            }

            .rv-24-form_single_box {
                display: flex;
                column-gap: 10px;
                color: #FFFFFF;

                i {
                    color: #FFFFFF;
                    font-size: 20px;
                    margin-top: 5px;
                }

                .rv-24-form_inputbox {
                    position: relative;
                    width: 135px;

                    label {
                        font-size: 16px;
                        font-weight: 700;
                        color: #FFFFFF;

                        @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                            font-size: 12px;
                        }
                    }

                    input,
                    select {
                        color: rgba(255, 255, 255, 0.7);
                        font-size: 14px;
                        font-weight: 500;
                        background: transparent;
                        font-family: var(--mulish);

                        option {
                            background: rgba(4, 14, 31, 0.1);
                            color: #040E1F;
                            padding: 0 10px;
                        }
                    }

                    #CheckIn::-webkit-calendar-picker-indicator {
                        /* Hide the default calendar icon in Chrome */
                        background-color: white;
                        font-size: 12px;
                        border-radius: 50%;
                        padding: 5px;
                        font-family: var(--mulish);
                    }

                    #CheckOut::-webkit-calendar-picker-indicator {
                        /* Hide the default calendar icon in Chrome */
                        background-color: white;
                        font-size: 12px;
                        border-radius: 50%;
                        padding: 5px;
                        font-family: var(--mulish);
                    }

                    #CheckOut::-moz-calendar-picker-indicator {
                        /* Styling for Firefox */
                        background-color: white;
                        font-size: 12px;
                        border-radius: 50%;
                        padding: 5px;
                    }
                }

                .rv-24-form_btn {
                    background: #A58051;
                    height: 70px;
                    width: 200px;
                    text-align: center;
                    color: #FFFFFF;
                    line-height: 70px;
                    font-size: 16px;
                    font-weight: 700;
                    transition: all 0.5s ease;
                    font-family: var(--mulish);

                    @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                        height: 60px;
                        width: 170px;
                        line-height: 60px;
                    }

                    &:hover {
                        background: #FFFFFF;
                        color: #A58051;
                        letter-spacing: 1.5px;
                    }

                }

            }
        }
    }
}

// INDEX-25 BANNER
.rv-25-banner_main_section {
    position: relative;
    z-index: 1;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 250px;
    padding-bottom: 125px;

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-top: 240px;
        padding-bottom: 80px;
    }

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-top: 200px;
        padding-bottom: 60px;
    }

    @media #{$sm-mobile} {
        padding-top: 190px;
        padding-bottom: 55px;
        padding: 190px 0 55px 0;
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-image: linear-gradient(180deg, rgb(2 2 8 / 70%), rgb(2 2 8 / 70%));
    }

    &::before {
        position: absolute;
        content: "";
        bottom: 0;
        right: 0;
        width: 728px;
        height: 125px;
        z-index: 1;
        background: #D6DEC9;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            display: none;
        }
    }

    .rv-25-banner_content {
        border: 1px solid rgba(255, 255, 255, 0.50);
        padding: 80px 70px;

        @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
            padding: 70px 50px;
        }

        @media #{$sm,$mobile,$sm-mobile} {
            padding: 60px 30px;
        }

        @media #{$mobile,$sm-mobile} {
            padding: 40px 20px;
        }

        .rv-25-banner_content_sub_heading {
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
            font-family: "Mulish", sans-serif;
            display: block;

            @media #{$sm,$mobile,$sm-mobile} {
                margin-bottom: 20px;
            }

            @media #{$sm-mobile} {
                margin-bottom: 10px;
                font-size: 14px;
            }
        }

        .rv-25-banner_content_heading {
            color: #FFFFFF;
            font-size: 65px;
            font-weight: 600;
            font-family: "Mulish", sans-serif;
            text-transform: capitalize;

            @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                font-size: 60px;
            }

            @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                font-size: 50px;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                font-size: 40px;
            }

            @media #{$mobile,$sm-mobile} {
                font-size: 30px;
            }

            @media #{$sm-mobile} {
                font-size: 22px;
            }

            span.ityped {
                display: inline;
            }

            .ityped-cursor {
                display: inline;
            }
        }

        .rv-25-banner_content_desc {
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            margin: 30px 0 40px 0;
            font-family: var(--mulish);

            @media #{$mobile,$sm-mobile} {
                margin: 20px 0 30px 0;
            }

            @media #{$sm-mobile} {
                margin: 20px 0 30px 0;
                font-size: 14px;
            }
        }

        .rv-25-banner_button {
            background: #A07549;
            width: 242px;
            height: 54px;
            text-align: center;
            line-height: 54px;
            color: #FFFFFF;
            font-size: 16px;
            font-family: var(--mulish);
            font-weight: 700;
            transition: all 0.5s ease;
            position: relative;
            z-index: 1;
            display: inline-block;

            @media #{$sm,$mobile,$sm-mobile} {
                width: 210px;
            }

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                background: #FFFFFF;
                width: 0%;
                height: 100%;
                transition: all 0.4s ease;
                z-index: -1;
                left: auto;
            }

            &:hover {
                color: #020208;
            }

            &:hover::before {
                width: 100%;
                left: 0;
            }
        }

    }
}