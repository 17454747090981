/* ----- 07. Speakers ----- */
.rv-1-speakers {
    background-color: #E4E4E4;

    &__slider {
        .swiper-slide {
            &:nth-child(odd) {
                margin-top: 50px;

                @include xxs {
                    margin-top: 0;
                }
            }
        }

    }
}

.rv-1-speaker {
    width: 100%;
    height: 370px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:hover {
        .rv-1-speaker__socials a {
            transform: translateX(0);
            opacity: 1;
        }
    }

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__txt {
        position: absolute;
        left: 0;
        bottom: 24px;
    }

    &__role {
        background-color: var(--white-f);
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 14px;
        color: rgb(5 5 5 / 70%);
        display: inline-block;
        margin-bottom: 2px;
    }

    &__name {
        background-color: var(--white-f);
        padding: 8px 14px;
        font-weight: 600;
        font-size: 18px;
        color: var(--white-f);
    }

    &__socials {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        a {
            background-color: var(--white-f);
            border-radius: 50%;
            color: var(--white-f);
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            opacity: 0;
            transform: translateX(50px);
            transition: 0.4s ease;

            &:hover {
                background-color: var(--orange);
                color: var(--white-f);
            }

            &:nth-child(2) {
                transition: 0.4s ease, transform 0.4s ease 0.1s, opacity 0.4s ease 0.1s;
            }

            &:nth-child(3) {
                transition: 0.4s ease, transform 0.4s ease 0.2s, opacity 0.4s ease 0.2s;
            }
        }
    }
}